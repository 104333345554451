import React from 'react'

import ib1 from '../../../assets/iBrokers_10.jpg'
import ib2 from '../../../assets/iBrokers_11.jpg'

import ibenlarge1 from '../../../assets/ibroker_enlarge1.jpg'
import ibenlarge2 from '../../../assets/ibroker_enlarge2.jpg'

import pdf from '../../../assets/ibrokers_pdf.png'

import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { Heading, InnerWrapper, PreviewBox, Text } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import ImageDescBlock from '../../../ImageDescBlock'
import './iBroker.css'






// import pdf from '../../../assets/ibrokers_pdf.png'

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import iBrokePdf from '../../../assets/pdf/iBroker.pdf'

import TrackVisibility from 'react-on-screen'

import { useState } from "react"
import TopButton from '../../../components/TopButton/TopButton'

const VisibilityWrapper = ({
    children,
}) => {
    const [isInitial, setIsInitial] = useState(false);

    return (
        <>
            <TrackVisibility partialVisibility>
                {
                    ({ isVisible }) => {
                        if (!isInitial && !!isVisible) {

                            setIsInitial(true);
                        }
                        return (
                            <div className={`fade-in-section ${isInitial ? 'is-visible' : ''}`}>
                                {children}
                            </div>
                        )
                    }
                }
            </TrackVisibility>
        </>
    )
}

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const Section = styled.div`
    text-align: center;
`;

const IBroker = () => {
    const arrOfImages = [
        ibenlarge1,
        ibenlarge2,
    ];


    const [indexOfImages, setIndexOfImages] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const openModalAndSetIndex = (index) => {
        setIndexOfImages(index);
        setShowModal(true);
        return;
    };

    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });
    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading heading="iBrokers" />

                <VisibilityWrapper>
                    <>
                        <DecoDesc1>

                            iBrokers Insurance System is a comprehensive system designed especially for the insurance agent/broker which handles Customer Relationships, Policies, Renewals, and financial ledgers. It is a 100% web base application, so the user could access it through the internet. iBrokers is a one-stop solution to empower your leading position and enhance your competitiveness in the market.

                        </DecoDesc1>
                        <ImageDescBlock image={ib1} title="Features" titleAlign="center" imageFirst={false}>
                            <ul>
                                <li><Text>Support multi-level of CRM, brokerages, companies and currencies</Text></li>
                                <li><Text>Embedded a policy calculation engine to calculate profit sharing of the policy with different parties</Text></li>
                                <li><Text>Keep track of the policy history</Text></li>
                                <li><Text>Well integrated with the accounting system</Text></li>
                                <li><Text>Multi-level security control and user access right</Text></li>
                                <li><Text>Support different types of report formats, e.g. EXCEL, PDF, etc.</Text></li>
                            </ul>
                        </ImageDescBlock>

                        <ImageDescBlock image={ib2} title="Benefits" titleAlign="center">
                            <ul>
                                <li><Text>One-stop solution to complete the job from quotation to invoicing, and AR/AP</Text></li>
                                <li><Text>Centralize the policies and CRM data, management can view full pictures in real time</Text></li>
                                <li><Text>User can access iBrokers at anywhere, in anytime through any web browser</Text></li>
                                <li><Text>User friendly interfaces</Text></li>
                                <li><Text>Reduce the IT support costs</Text></li>
                                <li><Text>Easily tailor made to suit the uniqueness of a company</Text></li>
                            </ul>
                        </ImageDescBlock>
                    </>
                </VisibilityWrapper>


                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Webpage Preview
                    </StyledHeading>
                    <br />
                    <PreviewBox>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={ibenlarge1} style={{ width: '300px', objectFit: `contain`, cursor: 'zoom-in' }} alt="" onClick={() => openModalAndSetIndex(0)} />
                        </div>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={ibenlarge2} alt="" style={{ width: '300px', objectFit: `contain`, cursor: 'zoom-in' }} onClick={() => openModalAndSetIndex(1)} />
                        </div>
                    </PreviewBox>
                    {showModal && (
                        <Lightbox
                            mainSrc={arrOfImages[indexOfImages]}
                            nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                            prevSrc={
                                arrOfImages[
                                (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                ]
                            }
                            onCloseRequest={() => setShowModal(false)}
                            onMovePrevRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length + 1) % arrOfImages.length
                                )
                            }
                        />
                    )}
                    <br />
                    <a target='_blank' href={iBrokePdf} style={{ alignSelf: `center` }} ><img src={pdf} alt="" width={'30%'} /></a>
                </Section>
                <TopButton />
            </InnerWrapper>
        </div>
    )
}

export default IBroker
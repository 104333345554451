import React, { useEffect, useState } from 'react';

import "../../../components/DropdownList/dropdown.css";


import './JoinUsContent.css';


import styled, { withTheme } from 'styled-components';
import { device } from '../../../common.styles';
import { LocationDropDownList } from '../../../components/DropdownList/LocationDropDownList';
import { RecipeDropDownList } from '../../../components/DropdownList/RecipDropDownList';
import { TypeDropDownList } from '../../../components/DropdownList/TypeDropDownList';
import JoinUsTitle from '../JoinUsTitle/JoinUsTitle';

const DropdownWrapper = styled.div`
  // display: flex;
  // max-width: 800px;
  // width: 100%;
  // margin: auto;

  // section {
  //   width: 33.333%;
  //   padding: 0 8px;
  //   box-sizing: border-box;
  // }

  // @media only screen and ${device.mobileL} {
  //   display: inline;

  //   section {
  //     width: 100%;
  //   }
  // }
`;

const contentCss = `.content {
  flex-grow: 1;
}`;



// function onMenuClick() {

//   const [currentIndex, setCurrentIndex] = React.useState(0)

// }

const JoinUsContent = () => {

  const [recipeCategory, setRecipeCategory] = React.useState(null);
  const [typeCategory, setTypeCategory] = React.useState(null);
  const [locationCategory, setLocationCategory] = React.useState(null);

  const [filteredJobList, setFilteredJobList] = React.useState([]);

  const [jobsList, setJobsList] = useState([]);

  const doFilterJobList = () => {
    // filteredJobList.length = 0;

    var filteredJobs = jobsList.filter((it) => {
      var isValid = true;

      var filterFunc = ((cat, value) => {

        if (cat != null) {
          if (cat.filter) {
            if (!cat.filter(value)) {
              return false;
            }
          } else {
            if (value !== cat.id) {
              return false;
            }
          }
        }

        return true;
      })

      if (!filterFunc(locationCategory, it.location)) {
        isValid = false;
      }
      if (!filterFunc(typeCategory, it.type)) {
        isValid = false;
      }
      if (!filterFunc(recipeCategory, it.category)) {
        isValid = false;
      }

      return isValid;
    })

    setFilteredJobList([...filteredJobs]);
  }

  async function fetchJobs() {
    await fetch("http://localhost:5000/joinUs", {
      method: "get",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then(
      (result) => {
        result.json().then((data) => {setJobsList(data)});
      },
      (error) => {
        console.log('error: ', error)
      }
    )
  }

  useEffect(async () => {
    await fetchJobs();
    return () => { }
  }, [])



  useEffect(() => {
    doFilterJobList();
    return () => { }
  }, [
    recipeCategory,
    typeCategory,
    locationCategory,
    jobsList
  ])



  return (

    <div style={{ display: `flex`, flexDirection: `column ` }}>

      <div className='globalContent_contactus bottomHeight'>



        <div>
          <div className="joinUs-filter-wrapper">
            <DropdownWrapper className="joinUs-filter-list">

              <RecipeDropDownList
                category={recipeCategory}
                setCategory={setRecipeCategory}
                jobsList={jobsList}
              />
              <br />
              <TypeDropDownList
                category={typeCategory}
                setCategory={setTypeCategory}
                jobsList={jobsList}
              />
              <br />
              <LocationDropDownList
                category={locationCategory}
                setCategory={setLocationCategory}
                jobsList={jobsList}
              />

            </DropdownWrapper>
          </div>



          <div className='joinUsbg'>

            <section className="k-listgroup">

              {
                filteredJobList.length <= 0 ? (
                  <div>
                    <center>No result</center>
                  </div>
                ) : null
              }

              <ul >
                {
                  (filteredJobList || []).map((it) => {
                    return (
                      <li >
                        <JoinUsTitle title={it.title} type={it.type} locationValue={it.location} respon={it.responsibilies} require={it.requirement} />
                      </li>
                    )
                  })
                }
              </ul>
            </section>

          </div>

        </div>


      </div>

    </div>
  )
}

export default withTheme(JoinUsContent)

import { withTheme } from "styled-components"
import Award1 from '../../../assets/whyopm/Why OPM-award-02.png'
import Award2 from '../../../assets/whyopm/Why OPM-award-03.png'
import Award3 from '../../../assets/whyopm/Why OPM-award-04.png'
import Award4 from '../../../assets/whyopm/Why OPM-award-05.png'
import Award5 from '../../../assets/whyopm/Why OPM-award-06.png'
import Award6 from '../../../assets/whyopm/Why OPM-award-07.png'
import Award7 from '../../../assets/whyopm/Why OPM-award-08.png'
import Award8 from '../../../assets/whyopm/Why OPM-award-09.png'
import { Text } from "../../../common.styles"
import TopButton from "../../../components/TopButton/TopButton"
import "./OpmAwardList.css"

const OpmAwardList = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };
    return (
        <div className="award-list-section section">
            <TopButton isWhite={true} marginLeft={55} marginLeftMobileL={25} />
            <div className="item-wrapper">
                <div className="item">
                    <div>
                        <img src={Award1} alt="" />
                    </div>
                    <Text className="hrStyle">Most Prominent Network Security
                        Consultant Services of The Year</Text>
                    <div>
                        <img src={Award2} alt="" />
                    </div>
                </div>
                <div className="item">
                    <div>
                        <img src={Award3} alt="" />
                    </div>
                    <Text className="hrStyle">Hong Kong's Most Outstanding
                        Services Awards 2021</Text>
                    <div>
                        <img src={Award4} alt="" />
                    </div>
                </div>
                <div className="item">
                    <div>
                        <img src={Award5} alt="" />
                    </div>
                    <Text className="hrStyle">Most Valuable Services
                        Awards in Hong Kong 2017</Text>
                    <div>
                        <img src={Award6} alt="" />
                    </div>
                </div>
                <div className="item">
                    <div>
                        <img classname="thumb" src={Award7} alt="" />
                    </div>
                    <Text className="hrStyle">Business Achiever Awards
                        by ABCA 2017</Text>
                    <div>
                        <img classname="thumb" src={Award8} alt="" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default withTheme(OpmAwardList)
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductCMSmartIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.01 13.83">
      <path d="M50.19,457.56H36.3a1.59,1.59,0,0,1-1.55-1.63v-10.3h17v10.3A1.6,1.6,0,0,1,50.19,457.56ZM35.86,446.12v9.29a1,1,0,0,0,1,1H49.65a1,1,0,0,0,1-1v-9.29Z" transform="translate(-34.74 -443.73)" />
      <path d="M45.51,450.53h-.4V450a1.86,1.86,0,0,0-3.72-.09v.63H41a.15.15,0,0,0-.13.14h0v3.39a.54.54,0,0,0,.53.54H45.1a.52.52,0,0,0,.53-.54v-3.39A.13.13,0,0,0,45.51,450.53Zm-1.94,2.07.08.77a.19.19,0,0,1,0,.1.14.14,0,0,1-.1,0H43a.13.13,0,0,1-.13-.14h0l.09-.77a.53.53,0,0,1-.12-.74.52.52,0,0,1,.74-.11.53.53,0,0,1,.12.74.43.43,0,0,1-.12.11Zm.74-2.17v.1H42.19V450a1.06,1.06,0,1,1,2.12-.06v.5Z" transform="translate(-34.74 -443.73)" />
      <path d="M50.4,443.73H36.09a1.38,1.38,0,0,0-1.35,1.38v1h17v-1A1.36,1.36,0,0,0,50.4,443.73Zm-3.21,1.57a.5.5,0,1,1,.49-.51h0A.5.5,0,0,1,47.19,445.3Zm1.46,0a.49.49,0,0,1-.51-.49.5.5,0,1,1,1,0h0A.5.5,0,0,1,48.65,445.3Zm1.45,0a.5.5,0,0,1-.51-.49.51.51,0,0,1,.49-.51.49.49,0,0,1,.51.49h0a.49.49,0,0,1-.49.5Z" transform="translate(-34.74 -443.73)" />
    </svg>
  </IconWrapper>
);

export default ProductCMSmartIcon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductnFrontWebPwChangeIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 18.42 13.48">
      <path d="M35.07,789.77l-.12,1,1-.29.09.7-.94.07.61.83-.63.33-.43-.87-.38.87-.66-.33.62-.83-.94-.07.11-.7,1,.29-.11-1Z" transform="translate(-33.33 -778.95)" />
      <path d="M38.17,789.77l-.11,1,1-.29.09.7-.94.07.62.83-.63.33-.44-.87-.38.87-.65-.33.61-.83-.94-.07.11-.7,1,.29-.11-1Z" transform="translate(-33.33 -778.95)" />
      <path d="M41.27,789.77l-.11,1,1-.29.09.7-.95.07.62.83-.63.33-.43-.87-.38.87-.66-.33.61-.83-.94-.07.11-.7,1,.29-.12-1Z" transform="translate(-33.33 -778.95)" />
      <path d="M44.38,789.77l-.12,1,1-.29.09.7-.94.07.62.83-.64.33-.43-.87-.38.87L43,792.1l.62-.83-.94-.07.11-.7,1,.29-.11-1Z" transform="translate(-33.33 -778.95)" />
      <path d="M47.48,789.77l-.11,1,1-.29.09.7-1,.07.62.83-.63.33-.43-.87-.38.87-.66-.33.62-.83-.94-.07.1-.7,1,.29-.11-1Z" transform="translate(-33.33 -778.95)" />
      <path d="M43.89,785.32a3,3,0,0,1,.87-1.3v-1.41c0-.6-.37-.82-1-.82h-.4a2.58,2.58,0,0,0-5.14-.52,4.51,4.51,0,0,0,0,.52h-.41c-.6,0-.79.22-.79.82v3.78c0,.6.2,1.16.79,1.16H43.8l.2,0A3,3,0,0,1,43.89,785.32Zm-2.39,1.07H40.15l.26-1.88a.93.93,0,0,1-.45-.8.89.89,0,0,1,.87-.89.86.86,0,0,1,.87.86h0a1,1,0,0,1-.46.84Zm-2.43-4.6c0-1.66.88-2,1.75-2s1.76.37,1.76,2Z" transform="translate(-33.33 -778.95)" />
      <path d="M51.32,789.47a.22.22,0,0,1-.13,0l-1.86-1.23a.25.25,0,0,1-.06-.33h0l.19-.29a.23.23,0,0,1,.33-.06l1.86,1.23a.25.25,0,0,1,.06.33l-.19.28-.08-.06.08.06A.23.23,0,0,1,51.32,789.47Z" transform="translate(-33.33 -778.95)" />
      <path d="M49.45,787.78,49,787.5" transform="translate(-33.33 -778.95)" />
      <path d="M49.45,787.89l-.05,0-.47-.28a.1.1,0,0,1,0-.14.11.11,0,0,1,.15,0l.46.28a.1.1,0,0,1,0,.14h0A.11.11,0,0,1,49.45,787.89Z" transform="translate(-33.33 -778.95)" />
      <path d="M49.11,785.12a2.62,2.62,0,1,0-1.19,3.5,2.57,2.57,0,0,0,1.32-1.5A2.62,2.62,0,0,0,49.11,785.12Zm-2.37,3.14a2,2,0,1,1,2-1.94h0a2,2,0,0,1-2,1.94Z" transform="translate(-33.33 -778.95)" />
    </svg>
  </IconWrapper>
);

export default ProductnFrontWebPwChangeIcon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;

  svg {
    fill: #333;
  }
`;

const MenuIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg width="50" height="50" viewBox="0 0 2000 2000">
      <path d="M1418.75,779.17H581.25a31.25,31.25,0,0,1,0-62.5h837.5a31.25,31.25,0,0,1,0,62.5Z" />
      <path d="M1418.75,1031.25H581.25a31.25,31.25,0,0,1,0-62.5h837.5a31.25,31.25,0,0,1,0,62.5Z" />
      <path d="M1418.75,1283.33H581.25a31.25,31.25,0,0,1,0-62.5h837.5a31.25,31.25,0,0,1,0,62.5Z" />
    </svg>
  </IconWrapper>
);

export default MenuIcon;




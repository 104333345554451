import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const ServiceTSIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.01 20.39">
      <path d="M18.33,224.49a.54.54,0,0,0-.64-.4.51.51,0,0,0-.26.14l-2,2.05-1.29-.48-.48-1.29,2.05-2a.53.53,0,0,0,0-.75.61.61,0,0,0-.26-.15,3.88,3.88,0,0,0-4.64,2.92,3.74,3.74,0,0,0,0,1.49.6.6,0,0,1-.17.49L2,235a2,2,0,0,0,0,2.87,2,2,0,0,0,1.45.6,2.09,2.09,0,0,0,1.48-.61l8.5-8.5a.58.58,0,0,1,.48-.17,3.88,3.88,0,0,0,4.46-3.2,3.93,3.93,0,0,0,0-1.49Zm-1.63,3a3.06,3.06,0,0,1-2.65.85,1.42,1.42,0,0,0-1.2.4l-8.5,8.5a1.24,1.24,0,0,1-1.74,0,1.22,1.22,0,0,1,0-1.72h0l8.53-8.53a1.44,1.44,0,0,0,.4-1.21,3,3,0,0,1,2.51-3.5,4,4,0,0,1,.49,0h.11L12.93,224a.64.64,0,0,0-.15.66l.56,1.5a.62.62,0,0,0,.37.37l1.5.55a.64.64,0,0,0,.67-.14l1.71-1.72A3.05,3.05,0,0,1,16.7,227.51Z" transform="translate(-1.41 -221.47)" />
      <path d="M3.79,235.51l-.53.54a.4.4,0,0,0,0,.58.42.42,0,0,0,.57,0l.53-.53a.42.42,0,0,0-.59-.59Z" transform="translate(-1.41 -221.47)" />
      <path d="M15.74,233.71h-.49a.87.87,0,0,1-.82-.55v0a.87.87,0,0,1,.17-1l.34-.35a.6.6,0,0,0,0-.85h0l-.52-.49a.41.41,0,0,0-.62.55l.05.05.34.32-.18.19a1.7,1.7,0,0,0-.33,1.88h0a1.71,1.71,0,0,0,1.6,1.06h.26l0,1.14h-.26a1.72,1.72,0,0,0-1.58,1.1h0a1.72,1.72,0,0,0,.39,1.87l.18.18-.8.82-.18-.18a1.7,1.7,0,0,0-1.88-.34h0a1.72,1.72,0,0,0-1.05,1.6V241L9.2,241v-.26a1.71,1.71,0,0,0-1.1-1.57h0l-.15.38.15-.39a1.73,1.73,0,0,0-1.88.39l-.18.19-.45-.43a.41.41,0,0,0-.59,0,.42.42,0,0,0,0,.59l0,0,.62.58a.59.59,0,0,0,.84,0l.35-.35a.86.86,0,0,1,1-.2h0a.9.9,0,0,1,.57.81v.49a.61.61,0,0,0,.6.59H9l1.6,0a.59.59,0,0,0,.59-.6v-.5a.87.87,0,0,1,.54-.82h0a.88.88,0,0,1,1,.17l.35.34a.6.6,0,0,0,.85,0L15,239.26a.6.6,0,0,0,0-.85l-.35-.35a.86.86,0,0,1-.2-1v0a.87.87,0,0,1,.81-.57h.49a.6.6,0,0,0,.59-.61l0-1.6a.6.6,0,0,0-.6-.58Z" transform="translate(-1.41 -221.47)" />
      <path d="M9.73,237.59A2.36,2.36,0,0,1,8,236.91a.42.42,0,0,0-.58.6,3.22,3.22,0,0,0,2.26.92h0A3.25,3.25,0,0,0,12,232.87a.42.42,0,0,0-.58.6,2.41,2.41,0,0,1-1.65,4.13Z" transform="translate(-1.41 -221.47)" />
    </svg>
  </IconWrapper>
);

export default ServiceTSIcon;




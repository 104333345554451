import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 100px;
  height: 100px;

  @media only screen and ${device.mobileL} {
    width: 35px;
    height: 35px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth5Icon = ({ className, ...otherProps }) => (
    <IconWrapper className={className} {...otherProps}>
        <svg viewBox="0 0 133.016 133.016">
            <g data-name="Group 1632" transform="translate(-652.905 -3283.555)">
                <g data-name="Group 1631">
                    <g data-name="Group 1630">
                        <g data-name="Group 1629">
                            <g data-name="Group 1613">
                                <path data-name="Path 1106" d="M720.5 3355.446a20.306 20.306 0 1 1 14.357-5.948 20.337 20.337 0 0 1-14.357 5.948zm0-37.6a17.3 17.3 0 1 0 12.23 5.067 17.325 17.325 0 0 0-12.23-5.07z" />
                            </g>
                            <g data-name="Group 1614">
                                <path data-name="Path 1107" d="M702.341 3355.46a1.424 1.424 0 0 1-1.007-2.432l3.674-3.674a1.424 1.424 0 0 1 2.015 2.014l-3.674 3.675a1.421 1.421 0 0 1-1.008.417z" />
                            </g>
                            <g data-name="Group 1615">
                                <path data-name="Path 1108" d="M738.628 3355.471a1.422 1.422 0 0 1-1.008-.417l-3.737-3.738a1.425 1.425 0 0 1 2.015-2.015l3.737 3.738a1.424 1.424 0 0 1-1.007 2.432z" />
                            </g>
                            <g data-name="Group 1616">
                                <path data-name="Path 1109" d="M720.613 3363.047a1.425 1.425 0 0 1-1.425-1.425v-5.472a1.425 1.425 0 0 1 2.85 0v5.472a1.425 1.425 0 0 1-1.425 1.425z" />
                            </g>
                            <g data-name="Group 1619">
                                <g data-name="Group 1617">
                                    <path data-name="Path 1110" d="M720.428 3379.1a7.129 7.129 0 1 1 7.129-7.128 7.137 7.137 0 0 1-7.129 7.128z" />
                                </g>
                                <g data-name="Group 1618">
                                    <path data-name="Path 1111" d="M725.475 3391.99h-10.093a4.364 4.364 0 0 1-4.217-5.482c1.211-4.548 4.627-9.443 9.263-9.443s8.053 4.9 9.264 9.443a4.364 4.364 0 0 1-4.217 5.482z" />
                                </g>
                            </g>
                            <g data-name="Group 1622">
                                <g data-name="Group 1620">
                                    <path data-name="Path 1112" d="M692.795 3367.179a6.7 6.7 0 1 1 6.7-6.7 6.71 6.71 0 0 1-6.7 6.7z" />
                                </g>
                                <g data-name="Group 1621">
                                    <path data-name="Path 1113" d="M697.465 3379.1h-9.34a4.145 4.145 0 0 1-4.005-5.207c1.131-4.246 4.329-8.816 8.675-8.816s7.544 4.57 8.675 8.817a4.144 4.144 0 0 1-4 5.206z" />
                                </g>
                            </g>
                            <g data-name="Group 1625">
                                <g data-name="Group 1623">
                                    <path data-name="Path 1114" d="M720.174 3334.7a6.7 6.7 0 1 1 6.7-6.7 6.71 6.71 0 0 1-6.7 6.7z" />
                                </g>
                                <g data-name="Group 1624">
                                    <path data-name="Path 1115" d="M724.845 3346.625H715.5a4.145 4.145 0 0 1-4.005-5.207c1.132-4.246 4.329-8.817 8.675-8.817s7.544 4.571 8.675 8.817a4.144 4.144 0 0 1-4 5.207z" />
                                </g>
                            </g>
                            <g data-name="Group 1628">
                                <g data-name="Group 1626">
                                    <path data-name="Path 1116" d="M748.181 3367.179a6.7 6.7 0 1 1 6.7-6.7 6.71 6.71 0 0 1-6.7 6.7z" />
                                </g>
                                <g data-name="Group 1627">
                                    <path data-name="Path 1117" d="M752.851 3379.1h-9.34a4.145 4.145 0 0 1-4.005-5.207c1.131-4.246 4.328-8.816 8.675-8.816s7.544 4.57 8.675 8.817a4.144 4.144 0 0 1-4.005 5.206z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <path d="M753.446 3310.111h-10.639a2.524 2.524 0 0 0-2.521 2.521v8.7a2.524 2.524 0 0 0 2.521 2.52h.381v1.832a.585.585 0 0 0 .363.542.593.593 0 0 0 .223.044.585.585 0 0 0 .415-.172l2.246-2.246h7.011a2.523 2.523 0 0 0 2.52-2.52v-8.7a2.523 2.523 0 0 0-2.52-2.521zm-8.7 7.822a.949.949 0 1 1 .949-.949.951.951 0 0 1-.954.949zm3.385 0a.949.949 0 1 1 .949-.949.95.95 0 0 1-.954.949zm3.386 0a.949.949 0 1 1 .948-.949.95.95 0 0 1-.953.949z" />
                </g>
            </g>
        </svg>



    </IconWrapper>
);

export default SolutionAuth5Icon;




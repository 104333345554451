import React from 'react'
import logo2 from '../../../assets/appdev_2.png'
import logo3 from '../../../assets/appdev_3.png'
import logo1 from '../../../assets/application_components.png'
import descImg from '../../../assets/application_img.png'
import serviceIbroker from '../../../assets/service_ibroker.png'
import serviceItrading from '../../../assets/service_itrading.png'
import SolutionSecurityIcon from '../../../assets/SolutionSecurityIcon'
import { InnerWrapper } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import ImagePointsBlock from '../../../ImagePointsBlock'
import SectionBlock from '../../../SectionBlock'
import FeaturesLoop from '../../../components/featuresLoop/FeaturesLoop'
import './ApplicationDev.css'

import TrackVisibility from 'react-on-screen'
import TopButton from '../../../components/TopButton/TopButton'

import { useState } from "react"


// import '././App.css'

const itemsArr = [
    { logo: logo1, text: "Gather different modules and components and build tailored applications for your business with our framework." },
    { logo: logo2, text: "Innovative professionals who are willing to listen and understand various requirements." },
    { logo: logo3, text: "Develop applications based on your requirements, reduce your cost and increase your return in application investments." },
]

const VisibilityWrapper = ({
    children,
}) => {
    const [isInitial, setIsInitial] = useState(false);

    return (
        <>
            <TrackVisibility partialVisibility>
                {
                    ({ isVisible }) => {
                        if (!isInitial && !!isVisible) {

                            setIsInitial(true);
                        }
                        return (
                            <div className={`fade-in-section ${isInitial ? 'is-visible' : ''}`}>
                                {children}
                            </div>
                        )
                    }
                }
            </TrackVisibility>
        </>
    )
}

const ApplicationDev = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div >
            <InnerWrapper>

                <DecoHeading desc="Applications Development" icon={<SolutionSecurityIcon />} />

                <VisibilityWrapper>
                    <>
                        <DecoDesc1>
                            Some companies may find it difficult to understand what applications are suited to their company and how to incorporate them. OPM can help your organization grow and thrive with our flexibility know how in the area of application development.
                        </DecoDesc1>
                    </>
                </VisibilityWrapper>

                <VisibilityWrapper>
                    <>
                        <ImagePointsBlock image={descImg} title="Features" desc="We have a deep understanding of industrial needs and great depth of application knowledge:" itemsArr={itemsArr} />
                    </>
                </VisibilityWrapper>

            </InnerWrapper>

            <TopButton marginLeft={15} marginLeftMobileL={15} />

            <FeaturesLoop />

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Products#iBrokers'><img src={serviceIbroker} alt="" height="50" style={{ marginRight: '16px' }} /></a>
                    <a href='/pages/Products#iTrading'><img src={serviceItrading} alt="" height="50" /></a>
                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default ApplicationDev
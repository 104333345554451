import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 100px;
  height: 100px;

  @media only screen and ${device.mobileL} {
    width: 35px;
    height: 35px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth4Icon = ({ className, ...otherProps }) => (
    <IconWrapper className={className} {...otherProps}>
        <svg viewBox="0 0 133.016 133.016">
            <g transform="translate(-1204.951 -3077.664)">
                <g>
                    <g>
                        <g data-name="Group 1829">
                            <g data-name="Group 1828">
                                <g data-name="Group 1827">
                                    <path data-name="Path 1340" d="M1304.742 3118.81a1.708 1.708 0 0 0-1.372-.721l-66.071-.114a1.713 1.713 0 0 0-1.372.716 2.442 2.442 0 0 0-.476 1.472l-.083 48.013a2.442 2.442 0 0 0 .471 1.474 1.715 1.715 0 0 0 1.372.721l66.07.114a2.047 2.047 0 0 0 1.849-2.188l.082-48.013a2.433 2.433 0 0 0-.47-1.474zm-1.042 1.471-.083 48.013c0 .421-.234.677-.335.677l-66.069-.114a.264.264 0 0 1-.168-.122.938.938 0 0 1-.164-.556l.083-48.014a.938.938 0 0 1 .165-.555.283.283 0 0 1 .17-.122l66.068.115a.263.263 0 0 1 .169.122.931.931 0 0 1 .164.556z" />
                                    <g data-name="Group 1822">
                                        <g data-name="Group 1821">
                                            <path data-name="Path 1341" d="m1255.968 3158.205-14.245-.025a.257.257 0 0 1 0-.514l14.244.025a.257.257 0 0 1 0 .514z" />
                                            <path data-name="Path 1342" d="m1255.963 3161.194-14.244-.024a.257.257 0 0 1 0-.514l14.244.025a.257.257 0 1 1 0 .513z" />
                                            <path data-name="Path 1343" d="m1255.957 3164.606-14.245-.024a.257.257 0 0 1 0-.514l14.245.025a.256.256 0 1 1 0 .513z" />
                                        </g>
                                        <path data-name="Rectangle 1717" transform="rotate(-89.901 2202.9 952.801)" d="M0 0h5.609v5.119H0z" />
                                    </g>
                                    <g data-name="Group 1824">
                                        <g data-name="Group 1823">
                                            <path data-name="Path 1344" d="m1276.988 3158.241-14.244-.025a.257.257 0 0 1-.257-.257.253.253 0 0 1 .258-.256l14.244.024a.257.257 0 0 1 0 .514z" />
                                            <path data-name="Path 1345" d="m1276.983 3161.231-14.244-.025a.257.257 0 0 1-.257-.257.253.253 0 0 1 .258-.257l14.244.025a.257.257 0 0 1 0 .514z" />
                                            <path data-name="Path 1346" d="m1276.977 3164.643-14.244-.025a.257.257 0 0 1-.257-.257.253.253 0 0 1 .258-.257l14.244.025a.257.257 0 0 1 0 .514z" />
                                        </g>
                                        <path data-name="Rectangle 1718" transform="rotate(-89.901 2213.428 942.29)" d="M0 0h5.609v5.119H0z" />
                                    </g>
                                    <g data-name="Group 1826">
                                        <g data-name="Group 1825">
                                            <path data-name="Path 1347" d="m1298.329 3158.278-14.244-.025a.257.257 0 1 1 0-.514l14.244.025a.257.257 0 0 1 0 .514z" />
                                            <path data-name="Path 1348" d="m1298.323 3161.268-14.245-.025a.257.257 0 0 1 0-.514l14.245.025a.257.257 0 0 1 0 .514z" />
                                            <path data-name="Path 1349" d="m1298.317 3164.679-14.244-.024a.257.257 0 1 1 0-.514l14.244.025a.256.256 0 1 1 0 .513z" />
                                        </g>
                                        <path data-name="Rectangle 1719" transform="rotate(-89.901 2224.116 931.62)" d="M0 0h5.609v5.119H0z" />
                                    </g>
                                    <path data-name="Path 1350" d="m1303.368 3118.846-66.067-.114a1.3 1.3 0 0 0-1.094 1.432l-.082 7.834 25.3.043a9.458 9.458 0 0 1 17.826.031l25.121.043.083-7.833a1.3 1.3 0 0 0-1.087-1.436zm-60.7 5.615a1.441 1.441 0 1 1 1.394-1.437 1.354 1.354 0 0 1-1.39 1.437zm5.266.009a1.441 1.441 0 1 1 1.394-1.437 1.353 1.353 0 0 1-1.39 1.437z" />
                                    <path data-name="Path 1351" d="M1297.577 3132.324h-17.843a9.465 9.465 0 0 1-18.8 0h-17.96a1.608 1.608 0 0 0-1.564 1.647l-.014 8.06a1.608 1.608 0 0 0 1.558 1.653l54.606-.012a1.608 1.608 0 0 0 1.564-1.647l.014-8.06a1.608 1.608 0 0 0-1.561-1.641z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <circle data-name="Ellipse 941" cx="8.337" cy="8.337" r="8.337" transform="translate(1261.997 3122.895)" />
                    <g data-name="Group 1832">
                        <g data-name="Group 1831">
                            <path data-name="Path 1352" d="M1276.426 3136.83a1.6 1.6 0 0 0-.979-.718c-.826-.206-2.145-.36-2.885-.57a.917.917 0 0 1-.668-.879v-.7a.313.313 0 0 1 .116-.24 3.553 3.553 0 0 0 .988-1.472 1.89 1.89 0 0 0 .1-.363.412.412 0 0 0 .412-.332 13.1 13.1 0 0 0 .272-1.516c0-.448-.166-.554-.362-.437a2.726 2.726 0 0 0 .1-.688 3.152 3.152 0 0 0-3.341-3.289 3.193 3.193 0 0 0-3.311 3.319 2.517 2.517 0 0 0 .1.688c-.2-.115-.36-.008-.358.44a13 13 0 0 0 .286 1.513.412.412 0 0 0 .415.329q.01.067.024.135a2.966 2.966 0 0 0 .966 1.69.313.313 0 0 1 .123.243v.7a.915.915 0 0 1-.654.884c-.738.217-1.868.444-2.694.657a1.6 1.6 0 0 0-.887.6 7.62 7.62 0 0 0 6.184 2.75 7.345 7.345 0 0 0 6.053-2.744z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>


    </IconWrapper>
);

export default SolutionAuth4Icon;




import React from 'react';

import styled, { css, withTheme } from 'styled-components';
import contactUsImg1 from '../../assets/contactus1.png';
import contactUsImg2 from '../../assets/contactus2.png';
import contactUsImg3 from '../../assets/contactus3.png';
import contactUsImg4 from '../../assets/contactus4.png';
import w3c1 from '../../assets/W3C HTML 4.01.png';
import w3c2 from '../../assets/W3C WAI-AA_WCAG 2.1.png';
import { device, Heading, Text } from '../../common.styles';
import './footer.css';


const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.bgcolor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 49.2px 27.8px 100px;
  ${props => props.haveSideMenu && css`
    padding-left: ${300 + 27.8}px;
  `};
  
  @media only screen and ${device.mobileL} {
    padding: 21.7px 27.8px 57.7px;
  }
  `;

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  color: white;
  justify-content: center;

  @media only screen and ${device.mobileL} {
    display: inline;
  }
`;

const Title = styled(Heading)`
  white-space: nowrap;
  
  @media only screen and ${device.mobileL} {

  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 32px;
  padding: 13.4px 0 0 32px;
  gap: 11px;

  @media only screen and ${device.mobileL} {
    padding: 11.3px 0 0 0px;
    gap: inherit;
  }
`;

const CopyRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding-top: 67.5px;
  text-align: center;
  color: white;

  @media only screen and ${device.mobileL} {
    padding-top: 24px;
  }
`;

const W3C = styled.div`
  position: absolute;
  height: 34.4px;
  right: 43.9px;
  bottom: 49.6px;
  display: flex;

  @media only screen and ${device.mobileL} {
    height: 21.9px;
    right: 8.3px;
    bottom: 12.7px;
  }

  a:first-child {
    margin-right: 24.9px;

    @media only screen and ${device.mobileL} {
      margin-right: 15.8px;
    }
  }

  a {
    width: 97.7px;
    height: 34.4px;

    @media only screen and ${device.mobileL} {
      width: 62.1px;
      height: 21.9px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const IconInfoBox = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSize.body}px;

  img {
    width: 20px;
    margin-right: 16px;

    @media only screen and ${device.mobileL} {
      width: 10px;
      margin-right: 8px;
    }
  }
`;

const IconInfo = ({ icon, text }) => (
  <IconInfoBox>
    <img src={icon} />
    <Text>{text}</Text>
  </IconInfoBox>
)

const Footer = ({ bgcolor = '#002361', haveSideMenu = false }) => {
  return (
    <Wrapper bgcolor={bgcolor} haveSideMenu={haveSideMenu}>
      <Content>
        <Title>Contact Us</Title>
        <Info>
          <IconInfo icon={contactUsImg1} text="+852 3743 8900" />
          <IconInfo icon={contactUsImg2} text="+852 3743 8901" />
          <IconInfo icon={contactUsImg3} text="info@opm.com.hk" />
          <IconInfo icon={contactUsImg4} text="Unit 1904-05, 19/F, Global Gateway Tower, 63 Wing Hong Street, Cheung Sha Wan" />
        </Info>
      </Content>
      <CopyRight>
        <Text>2022 Copyright © OPM Systems Company Ltd. </Text>
        <Text>All Rights Reserved.</Text>
      </CopyRight>
      <W3C>
        <a target='_blank' href='https://www.w3.org/TR/html4/'><img src={w3c1} /></a>
        <a target='_blank' href='https://www.w3.org/TR/WCAG20-TECHS/'><img src={w3c2} /></a>
      </W3C>
    </Wrapper>
  )
}

export default withTheme(Footer);
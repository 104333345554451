import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;

  .blue {
    fill: #002361;
  }

  .red {
    fill: #e1001e;
  }
`;

const OPMLogo = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 438.489 133.369">
      <g>
          <g>
              <path className='blue' d="M400.413 171.886q0 4.776-6.769 4.776h-6.231a9.065 9.065 0 0 1-4.886-1.053 4.114 4.114 0 0 1-1.946-3.681v-11.486a4.225 4.225 0 0 1 1.988-3.724 8.569 8.569 0 0 1 4.844-1.1h6.272q6.728 0 6.728 4.819zm-4.058-.757v-9.8a2.306 2.306 0 0 0-.848-2.052 5.129 5.129 0 0 0-2.795-.579h-4.327a5.225 5.225 0 0 0-2.835.579 2.3 2.3 0 0 0-.849 2.052v9.72a2.339 2.339 0 0 0 .931 2 5 5 0 0 0 2.753.548h4.327a5.373 5.373 0 0 0 2.774-.548 2.079 2.079 0 0 0 .869-1.92z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M425.254 164.229a3.9 3.9 0 0 1-1.635 3.313 7.366 7.366 0 0 1-4.472 1.209h-10.081v7.911h-4.12v-21.1h13.995a8.4 8.4 0 0 1 4.409 1.032 3.6 3.6 0 0 1 1.863 3.114q.044 2.122.041 4.521zm-4.12-.989v-2.5q0-2.125-3.29-2.126h-8.778v7.005h8.674q3.396-.003 3.394-2.379z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M457.879 176.767h-3.932v-16.556l-7.267 14.747a3.254 3.254 0 0 1-2.96 2.167 2.724 2.724 0 0 1-1.863-.632 5.34 5.34 0 0 1-.932-1.409l-7.431-14.873v16.556h-3.934v-18.325a2.5 2.5 0 0 1 .932-1.987 3.877 3.877 0 0 1 2.587-.789 3.46 3.46 0 0 1 3.438 1.956l7.161 14.916 7.288-14.937a3.468 3.468 0 0 1 3.394-1.978 3.8 3.8 0 0 1 2.609.811 2.647 2.647 0 0 1 .91 2.071z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M492.513 158.694h-10.558q-2.586 0-3.229.969a5.97 5.97 0 0 0-.372 2.672 1.912 1.912 0 0 0 .972 1.7 5.361 5.361 0 0 0 2.629.482h4.369a7.85 7.85 0 0 1 4.471 1.137 3.643 3.643 0 0 1 1.677 3.2v3.324a3.763 3.763 0 0 1-1.719 3.324 8.306 8.306 0 0 1-4.7 1.158h-11.778v-3.071h10.558q2.628 0 3.271-1.053a8.074 8.074 0 0 0 .289-2.84q0-2.145-3.435-2.145h-4.368a8.489 8.489 0 0 1-4.451-1.053 3.538 3.538 0 0 1-1.9-3.155v-3.282a3.988 3.988 0 0 1 2.069-3.514 7.863 7.863 0 0 1 4.2-.926h12.007z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="m517.831 155.623-9.5 12.476v8.563h-4.1V168.1l-9.461-12.476h4.7l6.708 9.131 6.955-9.131z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M537.312 158.694h-10.559q-2.586 0-3.229.969a5.97 5.97 0 0 0-.372 2.672 1.91 1.91 0 0 0 .972 1.7 5.358 5.358 0 0 0 2.629.482h4.368a7.85 7.85 0 0 1 4.471 1.137 3.644 3.644 0 0 1 1.678 3.2v3.324a3.765 3.765 0 0 1-1.718 3.324 8.313 8.313 0 0 1-4.7 1.158h-11.779v-3.071h10.558q2.629 0 3.271-1.053a8.038 8.038 0 0 0 .29-2.84q0-2.145-3.438-2.145h-4.366a8.5 8.5 0 0 1-4.452-1.053 3.539 3.539 0 0 1-1.9-3.155v-3.282a3.983 3.983 0 0 1 2.07-3.514 7.859 7.859 0 0 1 4.2-.926h12.008z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M558.344 158.631H551.3v18.031h-4.12v-18.031h-7v-3.071h18.156z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M578.383 167.679h-13.435v3.555q0 2.356 3.6 2.357h10.639v3.071h-11.755a8.846 8.846 0 0 1-4.741-1.074 3.805 3.805 0 0 1-1.863-3.387v-11.844a4.073 4.073 0 0 1 1.655-3.439 7.433 7.433 0 0 1 4.576-1.252h12.13v3.071h-10.681q-3.562 0-3.56 2.315v3.6h13.435z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M612.913 176.767h-3.935v-16.556l-7.266 14.747a3.256 3.256 0 0 1-2.96 2.167 2.732 2.732 0 0 1-1.863-.632 5.342 5.342 0 0 1-.931-1.409l-7.433-14.873v16.556h-3.932v-18.325a2.493 2.493 0 0 1 .93-1.987 3.88 3.88 0 0 1 2.59-.789 3.456 3.456 0 0 1 3.435 1.956l7.164 14.916L606 157.6a3.468 3.468 0 0 1 3.4-1.978 3.808 3.808 0 0 1 2.609.811 2.651 2.651 0 0 1 .911 2.071z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M635.042 158.694h-10.557q-2.59 0-3.229.969a5.951 5.951 0 0 0-.373 2.672 1.911 1.911 0 0 0 .973 1.7 5.356 5.356 0 0 0 2.629.482h4.368a7.85 7.85 0 0 1 4.471 1.137 3.644 3.644 0 0 1 1.678 3.2v3.324a3.766 3.766 0 0 1-1.72 3.324 8.3 8.3 0 0 1-4.7 1.158H616.8v-3.071h10.559q2.628 0 3.272-1.053a8.073 8.073 0 0 0 .289-2.84q0-2.145-3.436-2.145h-4.368a8.482 8.482 0 0 1-4.45-1.053 3.537 3.537 0 0 1-1.906-3.155v-3.282a3.988 3.988 0 0 1 2.069-3.514 7.861 7.861 0 0 1 4.2-.926h12.007z" transform="translate(-196.869 -116.274)"/>
          </g>
          <g>
              <path className='red' d="M393.924 186.863h-7.392a3.4 3.4 0 0 0-1.735.329 1.423 1.423 0 0 0-.635 1.233v6.781a1.623 1.623 0 0 0 .565 1.419 3.5 3.5 0 0 0 1.946.4h7.222v2.093h-8.266a5.105 5.105 0 0 1-3.159-.853 2.82 2.82 0 0 1-1.115-2.372v-7.928a2.783 2.783 0 0 1 1.184-2.538 6.32 6.32 0 0 1 3.316-.659h8.069z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M419.3 195.865q0 3.255-4.612 3.255h-4.246a6.175 6.175 0 0 1-3.329-.717 2.806 2.806 0 0 1-1.326-2.508v-7.827a2.88 2.88 0 0 1 1.354-2.539 5.839 5.839 0 0 1 3.3-.745h4.273q4.586 0 4.585 3.284zm-2.764-.516v-6.679a1.571 1.571 0 0 0-.579-1.4 3.488 3.488 0 0 0-1.9-.4h-2.947a3.551 3.551 0 0 0-1.932.4 1.569 1.569 0 0 0-.579 1.4v6.622a1.6 1.6 0 0 0 .635 1.362 3.4 3.4 0 0 0 1.876.373h2.947a3.662 3.662 0 0 0 1.891-.373 1.419 1.419 0 0 0 .593-1.305z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M450.358 199.191h-2.68V187.91l-4.951 10.048a2.221 2.221 0 0 1-2.017 1.477 1.857 1.857 0 0 1-1.27-.431 3.617 3.617 0 0 1-.635-.96l-5.064-10.134v11.281h-2.68v-12.486a1.7 1.7 0 0 1 .635-1.355 2.647 2.647 0 0 1 1.763-.537 2.357 2.357 0 0 1 2.342 1.332l4.879 10.164 4.966-10.178a2.366 2.366 0 0 1 2.314-1.347 2.594 2.594 0 0 1 1.777.552 1.8 1.8 0 0 1 .621 1.412z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M475.922 190.648a2.654 2.654 0 0 1-1.113 2.257 5.03 5.03 0 0 1-3.047.824h-6.87v5.391h-2.807v-14.378h9.537a5.715 5.715 0 0 1 3 .7 2.454 2.454 0 0 1 1.27 2.121q.032 1.451.03 3.085zm-2.806-.676v-1.705q0-1.447-2.242-1.448h-5.982v4.774h5.908q2.316 0 2.316-1.621z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M502.068 199.12h-3.006l-1.678-3.513h-7.617l-1.679 3.513h-3l6.262-13.031a2.57 2.57 0 0 1 4.458 0zm-5.572-5.476-3.061-6.381-2.779 6.381z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M526.1 197.4a1.75 1.75 0 0 1-.579 1.346 2.78 2.78 0 0 1-3.259.144 6.242 6.242 0 0 1-.846-1.161l-6.531-10.107v11.526H512.2v-12.271q0-2.093 2.285-2.093a2.433 2.433 0 0 1 1.58.444 5.566 5.566 0 0 1 .874 1.162l6.475 10.092v-11.569h2.686z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="m551.759 184.784-6.475 8.5v5.835h-2.793v-5.835l-6.446-8.5h3.2l4.571 6.222 4.741-6.222z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M591.407 199.12h-7.461a7.554 7.554 0 0 1-3.767-.767 2.839 2.839 0 0 1-1.312-2.673v-10.91h2.808v10.407a1.659 1.659 0 0 0 .691 1.534 4.826 4.826 0 0 0 2.229.33h6.812z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M611.048 186.834h-4.8v12.286h-2.806v-12.286h-4.768v-2.092h12.37z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="M635.358 195.836a2.941 2.941 0 0 1-1.078 2.4 4.74 4.74 0 0 1-3.082.889h-9.791V184.77h9.734q4.219 0 4.217 3.211zm-2.764-.687v-6.6q0-1.721-2.314-1.72h-6.064v10.207h6.064q2.314.005 2.314-1.887z" transform="translate(-196.869 -116.274)"/>
          </g>
          <g>
              <path className='blue' d="M338.188 185.654 301.7 222.148l-6.67-6.669 32.523-32.516-24.166-24.163-13.635 13.866 10.3 10.295-18.767 18.768-18.767-18.768 10.318-10.322-13.616-13.886-24.21 24.21.03.023-6.723 6.613-3.944-3.945h-27.5l17.587 17.579-14.6 14.362 11.583 11.789 14.7-14.466 34.726 34.725 20.418-20.418 20.413 20.418 63.985-63.989zm-77.325 36.494-20.857-20.864 6.723-6.609 20.8 20.8z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="m295.029 215.479 32.523-32.515-24.165-24.164-13.635 13.866 10.3 10.3-18.768 18.768-13.748 13.748-6.669 6.67-20.857-20.864-11.693-11.688-3.944-3.944h-27.5l17.587 17.579 46.408 46.41 34.166-34.164z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="M333.858 175.934h27.5l-59.658-59.66-20.418 20.418-.2-.208v27.5l.2.2.046.049 13.755-13.748-.053-.049 6.67-6.67z" transform="translate(-196.869 -116.274)"/>
              <path className='red' d="m281.076 136.491-20.213-20.217-59.656 59.659h27.344v.155l32.311-32.315 6.669 6.67 13.748 13.748-.2-.2z" transform="translate(-196.869 -116.274)"/>
              <path className='blue' d="m198.27 216.334 15.413 15.525 60.39-59.03-15.406-15.913-37.073 36.236z" transform="translate(-196.869 -116.274)"/>
          </g>
      </g>
  </svg>

  </IconWrapper>
);

export default OPMLogo;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductnFrontPwFilterIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 18.43 16.38">
      <path d="M35.44,679.39l-.14,1.25,1.26-.35.11.85-1.15.08.76,1-.77.41L35,681.57l-.46,1-.8-.4.75-1-1.15-.09.14-.84,1.23.35-.14-1.24Z" transform="translate(-33.32 -666.25)" />
      <path d="M39.21,679.39l-.14,1.25,1.26-.35.11.85-1.15.08.76,1-.77.41-.53-1.06-.46,1-.8-.4.75-1-1.15-.09.14-.84,1.23.35-.14-1.24Z" transform="translate(-33.32 -666.25)" />
      <path d="M43,679.39l-.14,1.25,1.27-.35.11.85-1.15.08.75,1-.77.41-.52-1.06-.46,1-.81-.4.75-1-1.14-.09.13-.84,1.23.35-.13-1.24Z" transform="translate(-33.32 -666.25)" />
      <path d="M46.75,679.39l-.14,1.25,1.26-.35.11.85-1.15.08.76,1-.77.41-.53-1.06-.46,1-.8-.4.75-1-1.15-.09.14-.84,1.23.35-.14-1.24Z" transform="translate(-33.32 -666.25)" />
      <path d="M50.52,679.39l-.14,1.25,1.26-.35.11.85-1.15.08.76,1-.77.41-.53-1.06-.46,1-.8-.4.75-1-1.14-.09.13-.84,1.23.35-.14-1.24Z" transform="translate(-33.32 -666.25)" />
      <path d="M38.83,676.69H46a1.29,1.29,0,0,0,1.18-1.39v-4.61c0-.73-.45-1-1.18-1h-.49c0-2.18-1.31-3.44-3.11-3.44s-3.12,1.26-3.12,3.44h-.49c-.73,0-1,.27-1,1v4.59C37.86,676,38.1,676.69,38.83,676.69Zm4.43-1.4H41.61l.32-2.29a1.13,1.13,0,0,1-.54-1A1.08,1.08,0,0,1,42.45,671a1,1,0,0,1,1,1h0a1.18,1.18,0,0,1-.56,1Zm-.83-8.06c1.07,0,2.14.45,2.14,2.46H40.3c0-2,1.07-2.46,2.14-2.46Z" transform="translate(-33.32 -666.25)" />
    </svg>
  </IconWrapper>
);

export default ProductnFrontPwFilterIcon;




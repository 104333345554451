import React, { useEffect, useState } from 'react';
import TrackVisibility from 'react-on-screen';
import styled, { css } from 'styled-components';
import { device } from '../../common.styles';

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1320px) {
    text-align: center;
    padding: 24px;
    box-sizing: border-box;
  }
`;

const RotateWrapper = styled.div`
  position: relative;
  top: calc(50% - ${props => props.size * 0.5}px);
  left: calc(50% - ${props => props.size * 0.5}px);
  transition: all 0.8s;
  opacity: 0;

  ${props => !props.isVisible && css`
    opacity: 0;
    transform: scale(.9) rotate(${360 / props.count * props.index + 120}deg) translateX(40%) rotate(-${360 / props.count * props.index + 120}deg);
  `}

  ${props => !!props.isVisible && css`
    opacity: 1;
    transform: scale(1) rotate(${360 / props.count * props.index + 163}deg) translateX(40%) rotate(-${360 / props.count * props.index + 163}deg);
  `}

  @media only screen and (max-width: 1320px) {
    opacity: 1;
    transform: none;
    top: auto;
    left: auto;
    width: 20%;
    display: inline-flex;
    padding: 8px;
    box-sizing: border-box;
  }

  @media only screen and ${device.mobileL} {
    width: 25%;
  }
`;

const ItemBox = styled.div`
  position:absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  @media only screen and (max-width: 1320px) {
    position: relative;
    width: 100%;
    height: 100%;
  }

`;

const StyledTrackVisibility = styled(TrackVisibility)`
  position: relative;
  height: 100%;
`;

const CircleContent = ({ isVisible, itemsArr, size }) => {
  const [isInitial, setIsInitial] = useState(false);

  useEffect(() => {
    if (!isInitial && !!isVisible) {
      setIsInitial(true);
    }
  }, [isInitial, isVisible])

  return (
    <Content>
      {itemsArr.map((item, index) => {
        return (
          <RotateWrapper key={index} count={itemsArr.length} index={index + 1} isVisible={isInitial} size={size}>
            <ItemBox size={size}>
              {item}
            </ItemBox>
          </RotateWrapper>
        )
      })}
    </Content>
  );
}

const CircularBox = ({ itemsArr = [], size = 100 }) => {
  const [isInitial, setIsInitial] = useState(false);

  return (
    <StyledTrackVisibility partialVisibility>
      {({ isVisible }) => <CircleContent isVisible={isVisible} size={size} itemsArr={itemsArr} />}
    </StyledTrackVisibility>

  )
}


export default CircularBox
import React from 'react';
import styled from 'styled-components';
import { device } from './common.styles';
import FullWidthSection from './FullWidthSection';

const Wrapper = styled(FullWidthSection)`
    box-sizing: border-box;
    background-color: #23419a;
`;

const Content = styled.div`
    text-align: center;
    font-family: AB;
    font-size: ${props => props.theme.fontSize.heading}px !important;
    padding: 32px 16px;
    color: white;

    @media only screen and ${device.mobileL} {
      font-size: ${props => props.theme.fontSize.mobileHeading}px !important;
    }

    img {
      height: 62.7px;
      
      @media only screen and ${device.mobileL} {
        height: 26.4px;
      }
    }
`;

const SectionBlock = ({ className, children }) => (
  <Wrapper className={className}>
    <Content>{children}</Content>
  </Wrapper>
);

export default SectionBlock;
import topBlack from '../../assets/top.png'
import topWhite from '../../assets/topW.png'
import styled from 'styled-components';
import { device } from '../../common.styles';

const StyledImg = styled.img`
    object-fit: contain;
    width: 54px;
    cursor: pointer;
    margin-left: ${props => props.marginLeft}px;
    margin-top: ${props => props.marginTop}px;

    @media only screen and ${device.mobileL} {
        width: 27px;
        margin-left: ${props => props.marginLeftMobileL}px;
        margin-top: ${props => props.marginTopMobileL}px;
    }
`;

const TopButton = ({
    isWhite = false,
    marginLeft = 0,
    marginTop = 0,
    marginLeftMobileL = 0,
    marginTopMobileL = 0
}) => {
    const scrollToTop = event => {
        window.scrollTo(0, 0)
    };

    return (
        <StyledImg src={isWhite ? topWhite : topBlack} onClick={() => scrollToTop()} marginLeft={marginLeft} marginTop={marginTop} marginLeftMobileL={marginLeftMobileL} marginTopMobileL={marginTopMobileL}></StyledImg>
    )
}

export default TopButton
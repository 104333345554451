import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import styled, { css } from 'styled-components';
import CloseIcon from '../../assets/CloseIcon';
import MenuIcon from '../../assets/MenuIcon';
import OPMLogo from '../../assets/OPMLogo';
import { device, fontSizeArr } from '../../common.styles';
import MobileSidebar from '../../components/Sidebar/Sidebar';
import { ThemeContext } from '../../ThemeContext';

const menuItems = [
  // { label: 'Home', to: '/' },
  { label: 'Why OPM', to: '/pages/WhyOPM' },
  { label: 'Services', to: '/pages/Services' },
  { label: 'Products', to: '/pages/Products' },
  { label: 'Solutions', to: '/pages/Solutions' },
  { label: 'Contact Us', to: '/pages/ContactUs' },
  { label: 'Join Us', to: '/pages/JoinUs' },
]

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${props => props.bgImg && css`
    background-image: url(${props.bgImg});
    background-size: cover;
    background-position: center;
  `}

  &:before {
    display: block;
    content: ' ';
    padding-top: 500px;
  
    @media only screen and ${device.mobileL} {
      padding-top: 80px;
    }
  }
  
  @media only screen and ${device.mobileL} {
    margin-top: 80px;
  }
`;

const ContentBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px 11.5%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: ${props => props.contentAlign};

  @media only screen and ${device.mobileL} {
    padding: 8px 42px;
  }
`;

const Content = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const MobileWrapper = styled.div`
  @media only screen and ${device.mobileL} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 20;
  }
`;

const MobileMenuWrapper = styled.div`
  @media only screen and ${device.mobileL} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .8);
    display: none;

    ${props => props.showMenu && css`
      display: inline;
    `}
  }
`;

const NavBarBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  object-fit: contain;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.22) 2%, rgba(255, 255, 255, 0.38) 5%, rgba(255, 255, 255, 0.53) 7%, rgba(255, 255, 255, 0.66) 10%, rgba(255, 255, 255, 0.77) 13%, rgba(255, 255, 255, 0.85) 16%, rgba(255, 255, 255, 0.92) 20%, rgba(255, 255, 255, 0.96) 25%, rgba(255, 255, 255, 0.99) 31%, #fff 50%, rgba(255, 255, 255, 0.99) 69%, rgba(255, 255, 255, 0.96) 75%, rgba(255, 255, 255, 0.92) 80%, rgba(255, 255, 255, 0.85) 84%, rgba(255, 255, 255, 0.77) 87%, rgba(255, 255, 255, 0.66) 90%, rgba(255, 255, 255, 0.53) 93%, rgba(255, 255, 255, 0.38) 95%, rgba(255, 255, 255, 0.22) 98%, rgba(255, 255, 255, 0.04) 100%, rgba(255, 255, 255, 0));

  ${props => props.tone === 'dark' && css`
  mix-blend-mode: multiply;
  opacity: 1;
  background-image: linear-gradient(to right, rgba(133, 174, 217, 0) 0%, rgba(133, 174, 217, 0.09) 1%, rgba(133, 174, 217, 0.28) 3%, rgba(133, 174, 217, 0.45) 6%, rgba(133, 174, 217, 0.6) 8%, rgba(133, 174, 217, 0.73) 11%, rgba(133, 174, 217, 0.83) 15%, rgba(133, 174, 217, 0.91) 19%, rgba(133, 174, 217, 0.96) 24%, rgba(133, 174, 217, 0.99) 31%, #85aed9 50%, rgba(133, 174, 217, 0.99) 69%, rgba(133, 174, 217, 0.96) 76%, rgba(133, 174, 217, 0.91) 81%, rgba(133, 174, 217, 0.83) 85%, rgba(133, 174, 217, 0.73) 89%, rgba(133, 174, 217, 0.6) 92%, rgba(133, 174, 217, 0.45) 94%, rgba(133, 174, 217, 0.28) 97%, rgba(133, 174, 217, 0.09) 99%, rgba(133, 174, 217, 0) 100%);
  `}
`;

const NavBarWrapper = styled.div`
  position: relative;
  width: 100%;
  /* opacity: 0.6; */

  @media only screen and ${device.mobileL} {
    background-image: none;
    top: 0;
  }
`;

const NavBarMenu = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: white;
  display: none;

  @media only screen and ${device.mobileL} {
    display: flex;
    align-items: center; 
    padding: 16px;
    padding-left: 0;
    box-sizing: border-box;

    ${props => props.showMenu && css`
      display: none;
    `}
  }
`;

const StyledOPMLogo = styled(OPMLogo)`
  display: inline-block;
  width: 128px;
`;

const NavBarMenuClose = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;

  @media only screen and ${device.mobileL} {
    display: block;
  }
`;

const NavBar = styled.div`
  margin: auto;
  max-width: ${props => props.theme.maxWidth}px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media only screen and ${device.mobileL} {
    display: inline;
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: inline;
    background-color: rgba(255, 255, 255, .8); */
  }
`;

const NavBarLink = styled(Link)`
  position: relative;
  display: block;
  padding: 8px;
  color: #343434;

  font-size: ${props => props.theme.fontSize.body}px;

  ${props => props.tone === 'dark' && css`
    color: white;
  `}

  @media only screen and ${device.mobileL} {
    color: #343434;
  }

  &:hover {
    opacity: .7;
  }
`;

const TopBar = styled.div`
  position: absolute;
  top: 0;
  padding: 28px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${device.mobileL} {
    position: relative;
    display: inline;

    .font-changer {
      display: none;
    }
  }
`;

const TopBarLink = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  font-family: HR;
  font-size: 22px;
  font-weight: normal;
  color: #343434;

  ${props => props.tone === 'dark' && css`
    color: white;
  `}

  @media only screen and ${device.mobileL} {
    color: #343434;
  }

  &:hover {
    opacity: .7;
  }
`;

const FontChanger = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: baseline;
  color: #343434;
  ${props => props.tone === 'dark' && css`
      color: white;
  `}

  .font-button {
    width: 20px;
    cursor: pointer;
  }

  & > div {
    ${props => props.tone === 'dark' && css`
      color: white;
    `}

    @media only screen and ${device.mobileL} {
      color: #343434;
      padding: 8px;
    }
  }
`;

const Font1 = styled.div`
  font-size: 12px;

  ${props => props.active && css`
    text-decoration: underline;
  `}
`;

const Font2 = styled.div`
  font-size: 16px;


  ${props => props.active && css`
      text-decoration: underline;
    `}
`;

const Font3 = styled.div`
  font-size: 20px;


  ${props => props.active && css`
      text-decoration: underline;
    `}
`;

const FontIconArr = [Font1, Font2, Font3];

const FontChangerBlock = ({ theme, tone, currentFont, setSelectedTheme, onChange }) => (
  <FontChanger tone={tone}>
    {FontIconArr.map((FontItem, index) =>
      <FontItem key={index} className="font-button" active={currentFont === index} onClick={() => {
        onChange(index);
        setSelectedTheme({
          ...theme,
          fontSize: {
            heading: fontSizeArr[index].heading,
            sectionHeading: fontSizeArr[index].sectionHeading,
            sectionDesc: fontSizeArr[index].sectionDesc,
            sectionSubheading: fontSizeArr[index].sectionSubheading,
            body: fontSizeArr[index].body,
            mobileHeading: fontSizeArr[index].mobileHeading,
            mobileSectionHeading: fontSizeArr[index].mobileSectionHeading,
            mobileSectionDesc: fontSizeArr[index].mobileSectionDesc,
            mobileSectionSubheading: fontSizeArr[index].mobileSectionSubheading,
            mobileBody: fontSizeArr[index].mobileBody
          }
        });
      }}>A</FontItem>)}
  </FontChanger>
);

const MenuContent = ({ theme, tone, currentFont, setSelectedTheme, onChange }) => {
  const isMobile = useMediaQuery({ query: device.mobileL });
  return (
    <>
      <TopBar>
        <a href="/pages/Sitemap">
          <TopBarLink tone={tone}>Sitemap</TopBarLink></a>
        <a href="/pages/TextVersion">
          <TopBarLink tone={tone}>Text Version</TopBarLink>
        </a>
        <div style={{ width: '100%' }}></div>
        <TopBarLink tone={tone}><ScrollLink to="content" offset={isMobile ? -80 : null}>Skip to Content</ScrollLink></TopBarLink>
        <div className='font-changer'>
          <FontChangerBlock theme={theme} tone={tone} currentFont={currentFont} setSelectedTheme={setSelectedTheme} onChange={onChange} />
        </div>
      </TopBar>
      <NavBarWrapper>
        <NavBar>
          <NavBarBg tone={tone} />
          {menuItems.map(({ label, to }, index) => <NavBarLink key={index} to={to} tone={tone}>{label}</NavBarLink>)}
        </NavBar>
      </NavBarWrapper>
    </>
  );
}

const Header = ({ tone, bgImg, children, contentAlign = 'start' }) => {
  const [currentFont, setCurrentFont] = useState(1);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    // disable body scrolling when showing menu
    if (showMenu) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setShowMenu(!showMenu);
  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  return (
    <ThemeContext.Consumer>
      {({ theme, setSelectedTheme }) => (
        <Wrapper bgImg={bgImg}>
          <a href='/'>
            <ContentBox contentAlign={contentAlign}>
              <Content>
                {children}
              </Content>
            </ContentBox>
          </a>
          <MobileWrapper>
            <MobileMenuWrapper showMenu={showMenu}>
              {
                isMobile
                  ? <MobileSidebar onClick={toggleMenu} />
                  : <MenuContent theme={theme} tone={tone} currentFont={currentFont} setSelectedTheme={setSelectedTheme} onChange={(index) => setCurrentFont(index)} />
              }
              <NavBarMenuClose onClick={toggleMenu}>
                <CloseIcon />
              </NavBarMenuClose>
            </MobileMenuWrapper>
            <NavBarMenu showMenu={showMenu}>
              <MenuIcon onClick={toggleMenu} />
              <a href='/'><StyledOPMLogo /></a>
              <div style={{ width: '30%' }}></div>
              <FontChangerBlock theme={theme} tone={tone} currentFont={currentFont} setSelectedTheme={setSelectedTheme} onChange={(index) => setCurrentFont(index)} />
            </NavBarMenu>
          </MobileWrapper>
          <Element name="content"></Element>
        </Wrapper>
      )}
    </ThemeContext.Consumer>
  )
}

export default Header
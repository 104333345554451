import React from 'react'




import lineHon from '../../../assets/nFont_hon_line.png'


import wpc1 from '../../../assets/wpc1.jpg'
import wpc2 from '../../../assets/wpc2.jpg'


import styled from 'styled-components'
import { Heading, InnerWrapper, Text } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import ITradingBenefitGrid from '.././iTrading/ITradingBenefitGrid'
import TopButton from '../../../components/TopButton/TopButton'
import './nFontwpc.css'

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
    padding: 16px;
`;

const Section = styled.div`
    text-align: center;
`;

const FeaturesBox = styled.div`
    width: 100%;
    margin: auto;
    padding: 16px;
    box-sizing: border-box;
`;

const StyledHeading = styled(Heading)`
    color: #343434;
`;

const StyledText = styled(Text)`
    color: #343434;
`;

const StyledContentTitle = styled(StyledText)`
    font-family: AB;
    font-weight: bold;
    color: #00b0bd;
    margin-top: 30px;
`;

const NFontWPC = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <DecoHeading heading="nFront Web Password Change" />
            <DecoDesc1>
                The nFront Web Password Change is a password change portal for AD users which is "nFront Password Filter" aware. It is a small software package that you install on Internet Information Server.
                It understands the password policies defined with nFront Password Filter. When the user enters his or her name and tabs to the next field the password policy rules that apply to the specific account are
                retrieved and displayed.
            </DecoDesc1>
            <StyledInnerWrapper>
                <div className='nFontPFContent'>
                    <StyledContentTitle> A PASSWORD CHANGE PORTAL THAT IS "NFRONT" AWARE.</StyledContentTitle>

                    <StyledText className='alignStart'>
                        The nFront Web Password Change is a password change portal for AD users which is "nFront Password Filter" aware. It is a small software package that you install on Internet Information Server. It understands the password policies defined with nFront Password Filter. When the user enters his or her name and tabs to the next field the password policy rules that apply to the specific account are retrieved and displayed. If the user attempts a password change that is disallowed by nFront Password Filter a more detailed failure message will be displayed and the user will be given another attempt to change his or her password.
                    </StyledText>
                </div>


                <div className='nFontPFContent'>
                    <StyledContentTitle>When a user types in their password the password policy rules that apply to their specific account are displayed.</StyledContentTitle>
                    <img src={wpc1} style={{ width: `100%`, marginTop: `30px` }} />
                </div>

                <div className='nFontPFContent'>
                    <StyledContentTitle>If the user types a password that does not meet the rules, the screen refreshes and shows the exact reasons for failure.</StyledContentTitle>
                </div>
                <br />
            </StyledInnerWrapper>

            <TopButton />

            <StyledInnerWrapper>
                <div className='nFontPFContent'>
                    <img src={wpc2} style={{ width: `100%` }} />
                </div>


                <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, marginTop: `30px` }}>
                    <StyledHeading style={{ marginTop: `50px`, textAlign: 'center' }}>Benefits</StyledHeading>
                </div>

                <Section>
                    <FeaturesBox>
                        <ITradingBenefitGrid index={1} maxTextWidth={320} text="Great solution for offsite users or VPN users who need to change their password." />
                        <ITradingBenefitGrid index={2} maxTextWidth={320} text="You can reduce helpdesk calls from people who are not aware of the exact password policy requirements." />
                        <ITradingBenefitGrid index={3} maxTextWidth={320} text="You can load the software in minutes with no need to reboot." />
                    </FeaturesBox>
                </Section>

                <div style={{ display: `flex`, flexDirection: `column` }}>
                    <StyledHeading style={{ marginTop: `20px`, textAlign: 'center' }}>Features</StyledHeading>


                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        Windows 2003, 2008, 2012 or 2016 server (32-bit and 64-bit)<br />
                        Internet Information Server<br />
                        .NET Framework 3.5 or later installed<br />
                        nFront Password Filter installed on all Domain Controllers
                    </StyledText>

                    <img src={lineHon} />

                </div>



                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <StyledHeading className='subTitle_center' style={{ marginTop: `20px` }}>Up And Running In 5 Minutes</StyledHeading>

                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        You can install the software in less than 5 minutes. There is no configuration except for the registration code to be entered if you purchase the product.
                    </StyledText>

                    <img src={lineHon} />

                </div>




                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <StyledHeading style={{ marginTop: `20px`, textAlign: 'center' }}>Requirements</StyledHeading>


                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        Windows 2003, 2008, 2012 or 2016 server (32-bit and 64-bit)<br />
                        Internet Information Server<br />
                        .NET Framework 3.5 or later installed<br />
                        nFront Password Filter installed on all Domain Controllers
                    </StyledText>

                    <img src={lineHon} />

                </div>



                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <StyledHeading style={{ marginTop: `20px`, textAlign: 'center' }}>The Technicals</StyledHeading>


                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        The software uses the same client DLL that is used in the nFront Password Filter Client. The client DLL has the technology
                        to communicate with any DC using encrypted RPC to pull a list of password policy rules and to check the password against the rules. The software connects to the nFront Password Policy service on any DC. If the password meets the nFront  Password Filter rules a call to the standard password change API is initiated. The password change may still fail if the password is one that has been used in the past X passwords depending on your domain-level password history settings. </StyledText>
                </div>

            </StyledInnerWrapper>

            <TopButton />

        </div>
    )
}

export default NFontWPC
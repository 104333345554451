import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductnFrontAccDisablerIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 18.43 16.59">
      <path d="M35.32,899.45l-.13,1.17,1.2-.33.1.8-1.09.08.71,1-.72.38-.5-1-.44,1-.75-.38.7-1-1.08-.08.13-.8,1.16.33-.13-1.17Z" transform="translate(-33.32 -885.91)" />
      <path d="M38.89,899.45l-.13,1.17,1.19-.33.1.8-1.08.08.71,1-.73.38-.5-1-.43,1-.76-.38.71-1-1.08-.08.12-.8,1.17.33-.13-1.17Z" transform="translate(-33.32 -885.91)" />
      <path d="M42.46,899.45l-.13,1.17,1.19-.33.1.8-1.09.08.72,1-.73.38-.5-1-.44,1-.75-.38.71-1-1.09-.08.13-.8,1.17.33-.13-1.17Z" transform="translate(-33.32 -885.91)" />
      <path d="M46,899.45l-.13,1.17,1.19-.33.1.8-1.08.08.71,1-.73.38-.5-1-.43,1-.76-.38.71-1L44,901.09l.12-.8,1.17.33-.14-1.17Z" transform="translate(-33.32 -885.91)" />
      <path d="M49.59,899.45l-.13,1.17,1.19-.33.11.8-1.09.08.72,1-.73.38-.5-1-.44,1-.75-.38.7-1-1.08-.08.13-.8,1.17.33-.13-1.17Z" transform="translate(-33.32 -885.91)" />
      <path d="M46.1,893.06a4.52,4.52,0,0,0-1.67-1.72,5.6,5.6,0,0,0-1.19-.55l-.32-.08-.1,0a2.45,2.45,0,1,0-1.61,0h-.07a2.24,2.24,0,0,0-.37.1,4.9,4.9,0,0,0-1.16.54c-1.66,1-2.4,2.61-2.4,5.21a.38.38,0,0,0,.4.36.39.39,0,0,0,.37-.36,8.31,8.31,0,0,1,.21-2,3.9,3.9,0,0,1,7.42-.78A3.52,3.52,0,0,1,46.1,893.06Zm-4.09-3a1.68,1.68,0,1,1,.76-.18A1.64,1.64,0,0,1,42,890.06Z" transform="translate(-33.32 -885.91)" />
      <path d="M51.44,894.11a3,3,0,1,0,.15,2.29A3,3,0,0,0,51.44,894.11Zm-2.64-.84a2.19,2.19,0,0,1,1.9,3.2l-3.31-2.71a2.15,2.15,0,0,1,1.41-.49Zm-.08,4.37a2.19,2.19,0,0,1-2.15-2.22,2.23,2.23,0,0,1,.25-1l3.31,2.71a2.11,2.11,0,0,1-1.41.49Z" transform="translate(-33.32 -885.91)" />
    </svg>
  </IconWrapper>
);

export default ProductnFrontAccDisablerIcon;




import React from 'react'
import serviceHd from '../../../assets/service_hd.png'
import descImg from '../../../assets/technicalSupportFeaturesImg.jpg'
import logo1 from '../../../assets/technicalSupportLogo1.png'
import logo2 from '../../../assets/technicalSupportLogo2.png'
import logo3 from '../../../assets/technicalSupportLogo3.png'
import logo4 from '../../../assets/technicalSupportLogo4.png'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import SectionBlock from '../../../SectionBlock'
import './TechnicalSupport.css'
// import '././App.css'
import { Link } from 'react-scroll'
import ServiceTSIcon from '../../../assets/ServiceTSIcon'
import { device, InnerWrapper } from '../../../common.styles'
import ImagePointsBlock from '../../../ImagePointsBlock'

import TopButton from '../../../components/TopButton/TopButton'
import { useMediaQuery } from 'react-responsive'

const itemsArr = [
    { logo: logo1, text: "Professional and experienced technical support team." },
    { logo: logo2, text: "Applying KM (knowledge management) methodology in order to solve problems with the most suitable solutions." },
    { logo: logo3, text: "For issues that are unknown, the team will investigate the problems in all directions, to find the best solution and log back the new found cases and solutions in the KM system." },
    { logo: logo4, text: "99% of problems and issues can be solved on time." },
]

const TechnicalSupport = () => {
    const isMobile = useMediaQuery({ query: device.mobileL });

    return (

        <div>
            <InnerWrapper>
                <DecoHeading desc="Technical Support" icon={<ServiceTSIcon />} />
                <DecoDesc1>
                    At OPM, we have fully trained professionals who are always willing to help. We constantly upgrade
                    ourselves with the ever-advancing technological changes so that we stay ahead of the pack.
                    OPM are always on standby to lend you our expertise support and advice even if you have the
                    most uncommon technical issues.
                </DecoDesc1>
                <ImagePointsBlock image={descImg} title="Features" itemsArr={itemsArr} />
            </InnerWrapper>

            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href=''><Link to='HELP_DESK_SERVICES' offset={isMobile ? -80 : null} spy={true}><img src={serviceHd} alt="" height="50" /></Link></a>
                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />

        </div>
    )
}

export default TechnicalSupport
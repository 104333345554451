import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import solution1 from '../../../assets/solution1.png'
import solution2 from '../../../assets/solution2.png'
import solution3 from '../../../assets/solution3.png'
import solution4 from '../../../assets/solution4.png'
import solution5 from '../../../assets/solution5.png'
import solution6 from '../../../assets/solution6.png'
import { device, SectionHeading, Text } from '../../../common.styles'
import './Solution.css'

import Carousel from "react-elastic-carousel"

import TopButton from '../../../components/TopButton/TopButton'

const OpmSolutionList = styled.div`
  display: flex;
  align-items: start;

  @media only screen and ${device.tablet} {
    max-width: 600px;
    display: inline;
  }

  @media only screen and ${device.mobileL} {
    padding: 0 16px;
    box-sizing: border-box;
    display: inline;
  }
`;

const OpmSolutionItem = styled.div`
  width: 180px;

  img {
    width: 100%;
  }

  @media only screen and ${device.tablet} {
    vertical-align: top;
    display: inline-block;
    width: 33.333%;
  }

  @media only screen and ${device.mobileL} {
    vertical-align: top;
    display: inline-block;
    width: 50%;
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  font-family: AR;
  font-weight: normal;
  color: white;
  text-align: center;
`

const StyledText = styled(Text)`
  font-family: AB;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: white;
`

const Solution = () => {

  const responsive = [
    { breakPoint: 1000, cardsToShow: 3 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.

  ];

  //-----------------------ken----------------
  const navigate = useNavigate()
  function go(page) {

    navigate(page)
  }

  const handleClick = event => {
    window.scrollTo(0, 0)
  };

  const isMobile = useMediaQuery({ query: device.mobileL });

  const breakPoints = [
    { width: 550, itemsToShow: 3, itemsToScroll: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];

  //-----------------------ken----------------
  return (
    <div className='opm_solution'>



      <div className='opm_solution_frame'>

        <TopButton isWhite={true} marginLeft={148} marginTop={52} marginLeftMobileL={48} marginTopMobileL={52} />

        <StyledSectionHeading a href='/pages/Solutions'>Solutions</StyledSectionHeading>

        {
          !isMobile ?
            <div >
              <div className='opm_solution_icon' style={{ justifyContent: "center" }}>


                <OpmSolutionList>


                  <OpmSolutionItem>

                    <a href='/pages/Solutions#SECURITY' className='opm_solution_item'><img src={solution1} /></a>
                    <StyledText>Security</StyledText>

                  </OpmSolutionItem>
                  <OpmSolutionItem>

                    <a href='/pages/Solutions#END_USER' className='opm_solution_item'> <img src={solution2} /></a>
                    <StyledText>End User</StyledText>

                  </OpmSolutionItem>


                  <OpmSolutionItem>

                    <a href='/pages/Solutions#BY_INDUSTRY' className='opm_solution_item'> <img src={solution3} /></a>
                    <StyledText>By Industry</StyledText>

                  </OpmSolutionItem>


                  <OpmSolutionItem>
                    <a href='/pages/Solutions#BLOCKCHAIN' className='opm_solution_item'>
                      <img src={solution4} />
                    </a>
                    <StyledText>Blockchain</StyledText>

                  </OpmSolutionItem>


                  <OpmSolutionItem>
                    <a href='/pages/Solutions#AI' className='opm_solution_item'>
                      <img src={solution5} />
                    </a>
                    <StyledText>Artificial</StyledText>
                    <StyledText>Intelligence (AI)</StyledText>

                  </OpmSolutionItem>


                  <OpmSolutionItem>
                    <a href='/pages/Solutions#HYBRID_CLOUD_INFRA' className='opm_solution_item'>
                      <img src={solution6} />
                    </a>
                    <StyledText>Hybrid Cloud</StyledText>
                    <StyledText>Infrastructure</StyledText>

                  </OpmSolutionItem>
                </OpmSolutionList>


              </div>
            </div>
            : <>


              <div className="carousel-wrapper" style={{ paddingBottom: '30px' }}>
                <br />
                <Carousel breakPoints={breakPoints} pagination={false}>

                  <a href='/pages/Solutions#SECURITY'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution1} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>Security</StyledText>
                  </div></a>

                  <a href='/pages/Solutions#END_USER'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution2} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>End User</StyledText>
                  </div></a>

                  <a href='/pages/Solutions#BY_INDUSTRY'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution3} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>By Industry</StyledText>
                  </div> </a>

                  <a href='/pages/Solutions#BLOCKCHAIN'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution4} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>Blockchain</StyledText>
                  </div></a>

                  <a href='/pages/Solutions#AI'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution5} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>Artificial Intelligence (AI)</StyledText>
                  </div></a>

                  <a href='/pages/Solutions#HYBRID_CLOUD_INFRA'> <div style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>
                    <img src={solution6} alt="" width="64.8px" objectFit="contain" />
                    <br />
                    <StyledText>Hybrid Cloud Infrastructure</StyledText>
                  </div></a>

                  {/* {items.map((item) => (
                    <Item key={item}>{item}</Item>
                  ))} */}
                </Carousel>
              </div>

            </>
        }





        {/* <Slider responsive={responsive} >






        <h1>1</h1>
    <h1>2</h1>
    <h1>3</h1>
    <h1>4</h1>
        </Slider> */}



      </div >

    </div >
  )
}

export default Solution
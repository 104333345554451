import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 57.1px;
  height: 73.7px;

  @media only screen and ${device.mobileL} {
    width: 19.03px;
    height: 24.57px;
  }
  
  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading1Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 57.057 73.7">
      <g>
        <path transform="translate(40.272 52.849)" d="M0 0h6.534v1.093H0z" />
        <path transform="translate(40.272 55.797)" d="M0 0h6.534v1.093H0z" />
        <path d="M5080.564 1108.3a4.6 4.6 0 0 0-3.231-1.338h-47.916a4.57 4.57 0 0 0-4.572 4.57v64.568a4.568 4.568 0 0 0 4.572 4.569h47.916a4.568 4.568 0 0 0 4.569-4.569v-64.561a4.6 4.6 0 0 0-1.338-3.239zm-50.065 35.819v-4.731h11.746v4.731a1.1 1.1 0 0 1-1.1 1.1h-9.545a1.1 1.1 0 0 1-1.1-1.1zm12.419 30.883a1.1 1.1 0 0 1-1.1 1.1h-9.541a1.1 1.1 0 0 1-1.1-1.1v-9.542a1.1 1.1 0 0 1 1.1-1.1h9.541a1.1 1.1 0 0 1 1.1 1.1zm0-15.065a1.1 1.1 0 0 1-1.1 1.1h-9.544a1.1 1.1 0 0 1-1.1-1.1v-9.537a1.1 1.1 0 0 1 1.1-1.1h9.544a1.1 1.1 0 0 1 1.1 1.1zm3.148-15.818v-4.731h11.745v4.731a1.1 1.1 0 0 1-1.1 1.1h-9.544a1.1 1.1 0 0 1-1.1-1.1zm.139 6.275a1.1 1.1 0 0 1 1.1-1.1h9.544a1.1 1.1 0 0 1 1.1 1.1v9.543a1.1 1.1 0 0 1-1.1 1.1h-9.544a1.1 1.1 0 0 1-1.1-1.1zm11.795 24.611a1.1 1.1 0 0 1-1.1 1.1h-9.544a1.1 1.1 0 0 1-1.1-1.1v-9.542a1.1 1.1 0 0 1 1.1-1.1h9.544a1.1 1.1 0 0 1 1.1 1.1zm17.583 0a1.107 1.107 0 0 1-1.107 1.11h-11.841a1.108 1.108 0 0 1-1.111-1.11v-25.533a1.11 1.11 0 0 1 1.111-1.111h11.841a1.108 1.108 0 0 1 1.107 1.111zm-13.574-30.883v-4.731h11.743v4.731a1.1 1.1 0 0 1-1.1 1.1h-9.541a1.1 1.1 0 0 1-1.1-1.1zm14.336-8.243h-45.938v-18.553h45.938z" transform="translate(-5024.845 -1106.965)" />
      </g>
    </svg>

  </IconWrapper>
);

export default ProductiTrading1Icon;




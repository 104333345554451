import React, { useEffect, useState } from 'react'

import imageCircle from '../../../assets/opm_circle_bg.svg'
import imageLOGO from '../../../assets/whyopmImge.png'

import ball1 from '../../../assets/ball1.png'
import ball2 from '../../../assets/ball2.png'
import ball3 from '../../../assets/ball3.png'
import ball4 from '../../../assets/ball4.png'
import ball5 from '../../../assets/ball5.png'


import solution6 from '../../../assets/ball10.png'
import solution5 from '../../../assets/ball11.png'
import solution4 from '../../../assets/ball12.png'
import solution3 from '../../../assets/ball13.png'
import solution2 from '../../../assets/ball14.png'
import solution1 from '../../../assets/ball15.png'
import solution11 from '../../../assets/ball16.png'
import solution10 from '../../../assets/ball6.png'
import solution9 from '../../../assets/ball7.png'
import solution8 from '../../../assets/ball8.png'
import solution7 from '../../../assets/ball9.png'

import Carousel from "react-elastic-carousel"
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import md from '../../../assets/md.png'
import { device, SectionHeading, Text } from '../../../common.styles'


// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

import "./feature.css"


// import required modules
import TrackVisibility from 'react-on-screen'
import TopButton from '../../../components/TopButton/TopButton'



const DescAreaHome = styled.div`
  padding: 80px;
  display: flex;
  align-items: center;

  @media only screen and ${device.mobileL} {
    display: inline !important;
    padding: 24px 0px;
  }
`;

const FeatureWrapper = styled.div`
  width: 100%;
  height: 900px;
`;

const FeatureMobileWrapper = styled.div`
  width: 100%;
  
`;


const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #FFFFFF;
  color: #fff;
  margin: 15px;
  font-size: 4em;
`;


const CircularWrapper = styled.div`
  position: relative;
  max-width: 900px;
  height: 100%;
  margin: auto;
`;

const CircularBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AB;
  font-size: 48px;
  color: white;

  @media only screen and ${device.mobileL} {
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    display: block;
    padding: 8px 0;
  }
`;

const FeatureBlock = styled.a`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  display: flex;

  @media only screen and ${device.mobileL} {
    display: inline;
  }
`;

const StyledText = styled(Text)`
  font-family: AB;
  line-height: 1.2;
  text-align: center;
  color: white;
`;

const FeaturesInnerSingleIcon = ({
  page,
  isVisible,
  isExpanded,
  width,
  angle = 0,
  text = "",
  iconUrl = null,
  rescaleDividend = 9,
  textOffsetTop = 0,
  textOffsetLeft = 0,
}) => {
  const [isInitial, setIsInitial] = useState(false);

  useEffect(() => {
    if (!isInitial && !!isVisible) {
      setIsInitial(true);
    }
  }, [isInitial, isVisible])

  const iconSize = width / rescaleDividend;
  const iconCornerRadius = iconSize / 2
  const iconSizeHalf = iconSize / 2;
  const radius = width / 2;

  const tx = radius - iconSizeHalf;
  var ty = radius - iconSizeHalf;
  var tr = isExpanded ? 0 : -205;
  var to = isExpanded ? 1 : 0;

  var xx = 0;
  var xy = 0;
  if (isExpanded) {
    xx = radius - iconSizeHalf;
  }


  const handleClick = event => {
    window.scrollTo(0, 0)
  };
  return (
    <div style={{
      position: `absolute`,
      transform: `translate(${tx}px, ${ty}px) rotate(${tr + angle}deg) `,
      transitionProperty: `all`,
      transitionDuration: `0s`,
    }}>
      <div style={{
        borderRadius: iconCornerRadius,
        // opacity: to,
        width: iconSize, height: iconSize, color: `#fff`,
        transform: isInitial ? `scale(${to}) translate(${xx}px, ${xy}px) rotate(${-tr - angle}deg) ` : ``,
        transitionProperty: `all`,
        transitionDuration: `1.0s`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
      }}>
        <a href={page}>
          <div style={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            position: `relative`,
          }}>
            <img style={{ width: iconSize, height: iconSize }} src={iconUrl} />
            <div style={{
              backgroundColor: `rgba(0,0,0,0.5)`,
              fontSize: iconSize / (10),
              fontFamily: 'AR',
              fontWeight: 'bold',
              padding: 10,
              overflow: `visible`,
              textAlign: `center`,
              whiteSpace: 'pre',
              transform: isInitial ? `translate(${iconSize * (textOffsetLeft)}px, ${iconSize * (textOffsetTop)}px)` : ``,
            }}>
              <StyledText>{text}</StyledText>
            </div>
          </div>
        </a>
      </div>
    </div>

  )
}





const StyledSectionHeading = styled(SectionHeading)`
  font-family: AR;
  font-weight: normal;
  color: white;
  padding-bottom: 30px;
  
  @media only screen and ${device.mobileL} {
    text-align: center;
    padding-bottom: 8px;
  }
`;

const StyledDesc = styled(Text)`
  text-align: left;

  @media only screen and ${device.mobileL} {
    text-align: center;
  }
`;

const StyledMobileSectionHeading = styled(SectionHeading)`
  margin-bottom: 8px;
  color: white;
`;

const MobileFeaturesInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 80.74px;
    height: 80.74px;
  }
`;

const MobileFeaturesInnerTextContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
`;

const WhyOPMContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    padding-right: 20px;
    padding-left: 20px;

    @media only screen and ${device.mobileL} {
      padding: 0 34px;
    }`;


const Features = (props) => {
  let [width, setWidth] = React.useState(null)
  let [height, setHeight] = React.useState(null)
  let [isExpanded, setIsExpanded] = React.useState(false)
  const isMobile = useMediaQuery({ query: device.mobileL });
  const { contentHashes } = props;

  React.useEffect(() => {
    if (width != null) {
      if (isExpanded == false) {
        setIsExpanded(true);
      }
    }
    return () => { }
  }, [width]);


  React.useEffect(() => {
    const reportWindowSize = () => {
      setWidth(window.innerWidth / 1.5)
      setHeight(window.innerHeight)
    }

    reportWindowSize();
    window.addEventListener('resize', reportWindowSize);

    return () => { window.removeEventListener('resize', reportWindowSize) }
  }, []);

  var icons = [
    { textOffsetTop: (-0.6), textOffsetLeft: -0.75, text: "Applications Development", iconUrl: require("../../../assets/ball1.png"), rescaleDividend: 7, angle: -70 - 90, page: '/pages/Services#Application-Development' },
    { textOffsetTop: (-0.3), textOffsetLeft: -1.1, text: "Consulting Services", iconUrl: require("../../../assets/ball2.png"), rescaleDividend: 7, angle: -35 - 90, page: '/pages/Services#Consulting-Services' },
    { textOffsetTop: (-0.6), textOffsetLeft: 0, text: "Technical Support", iconUrl: require("../../../assets/ball3.png"), rescaleDividend: 7, angle: 0 - 90, page: '/pages/Services#Technical-Support' },
    { textOffsetTop: (-0.3), textOffsetLeft: 1.3, text: "OPM Cloud Computing Services", iconUrl: require("../../../assets/ball4.png"), rescaleDividend: 7, angle: 35 - 90, page: '/pages/Services#OPM-Cloud-Computing-Services' },
    { textOffsetTop: (-0.6), textOffsetLeft: 0.75, text: "Help Desk Services", iconUrl: require("../../../assets/ball5.png"), rescaleDividend: 7, angle: 70 - 90, page: '/pages/Services#Help-Desk-Services' },
  ]

  const miscIconTexts = [
    "nFront Account Disabler",
    "nFront Web Password Change",
    "nFront Password Filter",
    "DIGITRADE",
    "iStorage",
    "CMSmart",
    "LDAP PROTECT",
    "PRIME",
    "iTrading",
    "iBrokers",
  ]

  const iconArr = [
    {text: "Alauda", iconUrl: require("../../../assets/ball16.png"), page: '/pages/Products#Alauda'},
    {text: "nFront\nAccount Disabler", iconUrl: require("../../../assets/ball6.png"), page: '/pages/Products#nFront-Account-Disabler'},
    {text: "nFront Web\nPassword Change", iconUrl: require("../../../assets/ball7.png"), page: '/pages/Products#nFront-Web-Password-Change'},
    {text: "nFront\nPassword Filter", iconUrl: require("../../../assets/ball8.png"), page: '/pages/Products#nFront-Password-Filiter'},
    {text: "DIGITTRADE", iconUrl: require("../../../assets/ball9.png"), page: '/pages/Products#DIGITTRADE'},
    {text: "iStorage", iconUrl: require("../../../assets/ball10.png"), page: '/pages/Products#iStorage'},
    {text: "CMSmart", iconUrl: require("../../../assets/ball11.png"), page: '/pages/Products#CMSmart'},
    {text: "LDAP PROTECT", iconUrl: require("../../../assets/ball12.png"), page: '/pages/Products#LDAP'},
    {text: "PRIME", iconUrl: require("../../../assets/ball13.png"), page: '/pages/Products#PRIME'},
    {text: "iTrading", iconUrl: require("../../../assets/ball14.png"), page: '/pages/Products#iTrading'},
    {text: "iBrokers", iconUrl: require("../../../assets/ball15.png"), page: '/pages/Products#iBrokers'},
  ]

  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.55 - (0.7 * 0 / 10), text:iconArr[0].text, iconUrl: iconArr[0].iconUrl, rescaleDividend: 9, angle: (-75 + 0 * 15) + 90, page: iconArr[0].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.55 - (0.7 * 1 / 10), text:iconArr[1].text, iconUrl: iconArr[1].iconUrl, rescaleDividend: 9, angle: (-75 + 1 * 15) + 90, page: iconArr[1].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 2 / 10), text:iconArr[2].text, iconUrl: iconArr[2].iconUrl, rescaleDividend: 9, angle: (-75 + 2 * 15) + 90, page: iconArr[2].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 3 / 10), text:iconArr[3].text, iconUrl: iconArr[3].iconUrl, rescaleDividend: 9, angle: (-75 + 3 * 15) + 90, page: iconArr[3].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 4 / 10), text:iconArr[4].text, iconUrl: iconArr[4].iconUrl, rescaleDividend: 9, angle: (-75 + 4 * 15) + 90, page: iconArr[4].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 5 / 10), text:iconArr[5].text, iconUrl: iconArr[5].iconUrl, rescaleDividend: 9, angle: (-75 + 5 * 15) + 90, page: iconArr[5].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 6 / 10), text:iconArr[6].text, iconUrl: iconArr[6].iconUrl, rescaleDividend: 9, angle: (-75 + 6 * 15) + 90, page: iconArr[6].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 7 / 10), text:iconArr[7].text, iconUrl: iconArr[7].iconUrl, rescaleDividend: 9, angle: (-75 + 7 * 15) + 90, page: iconArr[7].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.35 - (0.7 * 8 / 10), text:iconArr[8].text, iconUrl: iconArr[8].iconUrl, rescaleDividend: 9, angle: (-75 + 8 * 15) + 90, page: iconArr[8].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.15 - (0.7 * 8 / 10), text:iconArr[9].text, iconUrl: iconArr[9].iconUrl, rescaleDividend: 9, angle: (-75 + 9 * 15) + 90, page: iconArr[9].page })
  icons.push({ textOffsetTop: (0.6), textOffsetLeft: 0.05 - (0.7 * 8 / 10), text:iconArr[10].text, iconUrl:iconArr[10].iconUrl, rescaleDividend: 9, angle: (-75 + 10 * 15) + 90, page: iconArr[10].page })

  // for (var k = 0; k < 10; k++)
  // {
  //   icons.push(
  //     {
  //       textOffsetTop: (0.55), textOffsetLeft: 0.35 - (0.7 * k / 10), 
  //       text: `${miscIconTexts[k]}`, iconUrl: require("../../../assets/ball1.png"), rescaleDividend: 8, angle: (-75 + k*16.7 ) + 90},
  //   )





  // }

  const breakPoints = [
    { width: 550, itemsToShow: 3, itemsToScroll: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];

  return (
    <div className='opm_feature' style={{ display: `flex`, flexDirection: `column`, alignItems: `center`, justifyContent: `center`, position: `relative` }}>

      <DescAreaHome>

        <img id='opmLogo' style={{ width: `478px`, height: `293px` }} src={imageLOGO} />

        <WhyOPMContainer>

          <StyledSectionHeading>Why OPM</StyledSectionHeading>
          <StyledDesc>
            OPM is one of the leading solutions provider on Identity and Access Management (IAM).<br />
            We provide comprehensive deployment services to various network security solutions, software applications development and maintenance services to commercial corporations, government, semi-government sectors and education institutions.
            <br />We have an experienced team in solutions implementation and project management who help develop and deploy sophisticated business solutions that enhances business operations.
          </StyledDesc>
          <a href='/pages/WhyOPM' className='moreDetail'> <img src={md} alt="more detail" /></a>
        </WhyOPMContainer>
      </DescAreaHome>


      <div ref={(v) => { contentHashes[`#Services`] = v; }}></div>
      <div ref={(v) => { contentHashes[`#Products`] = v; }}></div>
      {
        !isMobile ?

          <div style={{ display: `flex`, alignItems: `center` }}>

            <div style={{ position: 'absolute', left: '148px', top: 'calc(50% + 175px)' }}><TopButton /></div>

            <div className='featureOuter'>

              <img src={imageCircle} width="100%" style={{ transform: 'scale(0.9)' }} />


              <div style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>

                {
                  (width != null
                    && height != null)
                    ? (
                      <TrackVisibility partialVisibility>
                        {
                          ({ isVisible }) =>
                            <div style={{
                              position: `relative`,
                              width: width,
                              height: width + 100
                            }}>
                              {
                                icons.map((it, i) => {
                                  return (

                                    <FeaturesInnerSingleIcon
                                      key={i}
                                      page={it.page}
                                      isVisible={isVisible}
                                      isExpanded={isExpanded}
                                      width={width}
                                      angle={it.angle}
                                      text={it.text}
                                      iconUrl={it.iconUrl}
                                      rescaleDividend={it.rescaleDividend}
                                      textOffsetTop={it.textOffsetTop}
                                      textOffsetLeft={it.textOffsetLeft} />

                                  )
                                })
                              }

                            </div>
                        }
                      </TrackVisibility>
                    ) : null
                }

              </div>

            </div>
          </div>
          : <>

            <FeatureMobileWrapper style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}>

              <StyledMobileSectionHeading>Services</StyledMobileSectionHeading>

              <div className="carousel-wrapper">
                <Carousel breakPoints={breakPoints} pagination={false} itemPadding={[0, 8]} >
                  <a href='/pages/Services#Application-Development'>
                    <MobileFeaturesInnerContainer>
                      <img src={ball1} alt="" />
                      <MobileFeaturesInnerTextContainer><StyledText>Applications Development</StyledText></MobileFeaturesInnerTextContainer>
                    </MobileFeaturesInnerContainer>
                  </a>

                  <a href='/pages/Services#Consulting-Services'>
                    <MobileFeaturesInnerContainer>
                      <img src={ball2} alt="" width="100%" />
                      <MobileFeaturesInnerTextContainer><StyledText>Consulting Services</StyledText></MobileFeaturesInnerTextContainer>
                    </MobileFeaturesInnerContainer>
                  </a>

                  <a href='/pages/Services#Technical-Support'>
                    <MobileFeaturesInnerContainer>
                      <img src={ball3} alt="" width="100%" />
                      <MobileFeaturesInnerTextContainer><StyledText>Technical Support</StyledText></MobileFeaturesInnerTextContainer>
                    </MobileFeaturesInnerContainer>
                  </a>

                  <a href='/pages/Services#OPM-Cloud-Computing-Services'>
                    <MobileFeaturesInnerContainer>
                      <img src={ball4} alt="" width="100%" />
                      <MobileFeaturesInnerTextContainer><StyledText>OPM Cloud Computing Services</StyledText></MobileFeaturesInnerTextContainer>
                    </MobileFeaturesInnerContainer>
                  </a>

                  <a href='/pages/Services#Help-Desk-Services'>
                    <MobileFeaturesInnerContainer>
                      <img src={ball5} alt="" width="100%" />
                      <MobileFeaturesInnerTextContainer><StyledText>Help Desk Services</StyledText></MobileFeaturesInnerTextContainer>
                    </MobileFeaturesInnerContainer>
                  </a>
                </Carousel>
              </div>

              <hr className="seperator" />

              <StyledMobileSectionHeading>Products</StyledMobileSectionHeading>

              <Carousel breakPoints={breakPoints} pagination={false} itemPadding={[0, 8]}>

                <a href='/pages/Products#iBrokers'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution1} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>iBrokers</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#iTrading'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution2} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>iTrading</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#PRIME'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution3} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>PRIME</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#LDAP'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution4} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>LDAP PROTECT</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#CMSmart'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution5} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>CMSmart</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#iStorage'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution6} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>iStorage</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#DIGITTRADE'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution7} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>DIGITTRADE</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#nFront-Password-Filiter'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution8} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>nFront Password Filter</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#nFront-Web-Password-Change'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution9} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>nFront Web Password Change</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#nFront-Account-Disabler'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution10} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>nFront Account Disabler</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

                <a href='/pages/Products#Alauda'>
                  <MobileFeaturesInnerContainer>
                    <img src={solution11} alt="" width="100%" />
                    <MobileFeaturesInnerTextContainer><StyledText>Alauda</StyledText></MobileFeaturesInnerTextContainer>
                  </MobileFeaturesInnerContainer>
                </a>

              </Carousel>
              <br />
            </FeatureMobileWrapper>
          </>
      }



    </div >

  )
}


export default Features
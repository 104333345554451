import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
    margin: 0 4px;
`;

const SolutionEndUserIcon = ({ className, ...otherProps }) => (
    <IconWrapper className={className} {...otherProps}>
        <svg viewBox="0 0 18.57 20.38">
            <path d="M9.29,10.92A9.3,9.3,0,0,0,0,20.2v.18H1.13V20.2a8.16,8.16,0,0,1,16.31-.36v.54h1.13V20.2A9.3,9.3,0,0,0,9.29,10.92Z" /><path class="cls-1" d="M9.29,0a6,6,0,1,0,6,6A6,6,0,0,0,9.29,0Zm4.86,6A4.86,4.86,0,1,1,9.29,1.14,4.86,4.86,0,0,1,14.15,6Z" />
        </svg>
    </IconWrapper>
);

export default SolutionEndUserIcon;
import React from 'react'




import p from '../../../assets/prime01.png'
import r from '../../../assets/prime02.png'
import i from '../../../assets/prime03.png'
import m from '../../../assets/prime04.png'
import e from '../../../assets/prime05.png'
import primeDesc1 from '../../../assets/prime_desc1.png'

import pri from '../../../assets/pri.png'
import w1 from '../../../assets/primew1.png'
import w2 from '../../../assets/primew2.png'


import styled from 'styled-components'
import { device, Heading, InnerWrapper, PreviewBox, Text } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import ImageDescBlock from '../../../ImageDescBlock'
import ITradingBenefitGrid from '../iTrading/ITradingBenefitGrid'
import './Prime.css'

import primePdf from '../../../assets/pdf/PRIME.pdf'

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useMediaQuery } from 'react-responsive'
import TopButton from '../../../components/TopButton/TopButton'

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const Section = styled.div`
    text-align: center;
`;

const SubSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 51px 64px 27.6px;
    gap: 30px;
    
    @media only screen and ${device.mobileL} {
        margin: 10px 0px 0px;
        gap: 9px;
    }
`;

const FeaturesBox = styled.div`
    width: 100%;
    margin: auto;
`;

const StyledITradingBenefitGrid = styled(ITradingBenefitGrid)`
    // width: 25%;

    // @media only screen and ${device.mobileL} {
    //     width: 50%;
    // }
`;

const IconPoint = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 76px;
        margin-right: 8.8px;
        object-fit: contain;

        @media only screen and ${device.mobileL} {
            width: 30px;
            margin-right: 3.2px;
        }
    }
`;

const StyledIconPointText = styled(Heading)`
    font-family: AL;
    font-weight: 300;
    color: #343434;
`;

const Prime = () => {
    const arrOfImages = [
        w1,
        w2,
    ];


    const [indexOfImages, setIndexOfImages] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const openModalAndSetIndex = (index) => {
        setIndexOfImages(index);
        setShowModal(true);
        return;
    };

    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <InnerWrapper>
                <DecoHeading heading="PRIME" />
                {
                    useMediaQuery({ query: device.mobileL }) ?
                        <>
                            <SubSection>
                                <IconPoint>
                                    <img src={p} alt="" /><StyledIconPointText>Post</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={r} alt="" /><StyledIconPointText>Rank</StyledIconPointText>
                                </IconPoint>
                            </SubSection>
                            <SubSection>
                                <IconPoint>
                                    <img src={i} alt="" /><StyledIconPointText>Identity</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={m} alt="" /><StyledIconPointText>Management</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={e} alt="" /><StyledIconPointText>Essentials</StyledIconPointText>
                                </IconPoint>
                            </SubSection>
                        </>
                        : <>
                            <SubSection>
                                <IconPoint>
                                    <img src={p} alt="" /><StyledIconPointText>Post</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={r} alt="" /><StyledIconPointText>Rank</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={i} alt="" /><StyledIconPointText>Identity</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={m} alt="" /><StyledIconPointText>Management</StyledIconPointText>
                                </IconPoint>
                                <IconPoint>
                                    <img src={e} alt="" /><StyledIconPointText>Essentials</StyledIconPointText>
                                </IconPoint>
                            </SubSection>
                        </>
                }
                <DecoDesc1>A cost-effective career transition solution which allows you to improve the overall experience of posting transition for all participants within your Government Department.
                    <br />
                    <br />

                    PRIME also helps organization to automate the complex provisioning process so users can have immediate access to resource across the organization systems and applications.
                </DecoDesc1>
                <ImageDescBlock image={primeDesc1} title="Features">
                    <ul>
                        <li><Text>Assign resources to users based on business roles and policies with role-based provisioning</Text></li>
                        <li><Text>Self-defining posts and ranks information</Text></li>
                        <li><Text>Filtered search </Text></li>
                        <li><Text>Provide audit trail</Text></li>
                        <li><Text>Multi-level security control and user access right</Text></li>
                    </ul>
                </ImageDescBlock>
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Benefits
                    </StyledHeading>
                    <br />
                    <br />
                    <FeaturesBox>
                        <StyledITradingBenefitGrid index={1} text="Update user’s attributes and roles simultaneously" />
                        <StyledITradingBenefitGrid index={2} text="Support two roles at the same time for one user" />
                        <StyledITradingBenefitGrid index={3} text="Generate up-to-date/provision complete posting list and related reports easily" />
                        <StyledITradingBenefitGrid index={4} text="Reduce IT support costs" />
                    </FeaturesBox>
                </Section>
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Webpage Preview
                    </StyledHeading>
                    <br />
                    <PreviewBox>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={w1} alt="" style={{ width: `300px`, height: `auto`, cursor: 'zoom-in' }} onClick={() => setShowModal(true)} />
                        </div>
                        <div>
                            <Text style={{ textAlign: 'left' }}>Click to enlarge</Text>
                            <img src={w2} alt="" style={{ width: `300px`, height: `auto`, cursor: 'zoom-in' }} onClick={() => setShowModal(true)} />
                        </div>
                    </PreviewBox>
                    {showModal && (
                        <Lightbox
                            mainSrc={arrOfImages[indexOfImages]}
                            nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                            prevSrc={
                                arrOfImages[
                                (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                ]
                            }
                            onCloseRequest={() => setShowModal(false)}
                            onMovePrevRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length - 1) % arrOfImages.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setIndexOfImages(
                                    (indexOfImages + arrOfImages.length + 1) % arrOfImages.length
                                )
                            }
                        />
                    )}
                    <br />
                    <a href={primePdf} style={{ alignSelf: `center` }} target="_blank"><img src={pri} alt="" width={'24%'} /></a>
                </Section>
                <TopButton />
            </InnerWrapper>
        </div>
    )
}

export default Prime
import React from 'react'
import { Helmet } from 'react-helmet'
import JoinUsContent from './JoinUsContent/JoinUsContent'
import { Footer, Header } from '../../containers'
import joinTopBg from '../../assets/join_top_bg.png'
import styled from 'styled-components'
import OPMLogo from '../../assets/OPMLogo'
import { device } from '../../common.styles'
import { useMemo, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-default/dist/all.css";
import "../../components/DropdownList/dropdown.css";

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AR;
  font-size: 88px;
  color: #343434;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`
const JoinUs = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join Us - Welcome to OPM System Company Limited</title>
        <meta name="Join Us" content="OPM - Join Us" />
      </Helmet>
      <Header bgImg={joinTopBg} contentAlign='end'>
        <StyledOPMLogo />
        <Slogan1>
          Join Us
        </Slogan1>
      </Header>
      <JoinUsContent />
      <Footer bgcolor='#343434' />
    </>
  )
}

export default JoinUs
import React from 'react'




import idenacc1 from '../../../../assets/idenacc1.png'
import idenacc11 from '../../../../assets/idenacc11.png'
import idenacc2 from '../../../../assets/idenacc2.png'
import idenacc3 from '../../../../assets/idenacc3.png'
import idenacc4 from '../../../../assets/idenacc4.png'

import iden1 from '../../../../assets/iden1.png'

import styled from 'styled-components'
import bl from '../../../../assets/bottomline.png'
import ldapLogo from '../../../../assets/ldapLogo.png'
import microLogo from '../../../../assets/microLogo.png'
import primeLogo from '../../../../assets/primeLogo.png'
import serviceAd from '../../../../assets/service_ad.png'
import serviceCs from '../../../../assets/service_cs.png'
import serviceTs from '../../../../assets/service_ts.png'
import SolutionAuth1Icon from '../../../../assets/SolutionAuth1Icon'
import SolutionAuth2Icon from '../../../../assets/SolutionAuth2Icon'
import SolutionAuth3Icon from '../../../../assets/SolutionAuth3Icon'
import SolutionAuth4Icon from '../../../../assets/SolutionAuth4Icon'
import SolutionAuth5Icon from '../../../../assets/SolutionAuth5Icon'
import SolutionAuth6Icon from '../../../../assets/SolutionAuth6Icon'
import SolutionSecurityIcon from '../../../../assets/SolutionSecurityIcon'
import { device, Heading, InnerWrapper, Text } from '../../../../common.styles'
import TopButton from '../../../../components/TopButton/TopButton'
import DecoDesc1 from '../../../../DecoDesc1'
import DecoHeading from '../../../../DecoHeading'
import SectionBlock from '../../../../SectionBlock'
import FullWidthSection from '../../../../FullWidthSection'
import AuthBlock from './AuthBlock'
import './SecurityIdentity.css'

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
    object-fit: contain;
`;

const StyledGradInnerWrapper = styled(FullWidthSection)`
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #eceded 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#eceded 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#eceded 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceded',GradientType=0 );
    text-align: center;
`

const AuthDescBox = styled(Text)`
    position: relative;
    top: 60px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 16px #333;
    max-width: 1000px;
    width: 100%;
    padding: 24px;
    margin: 16px auto;
    box-sizing: border-box;
    text-align: center;
    color: #343434;

    &::after {
        display: block;
        content: ' ';
        margin-top: 16px;
        width: 100%;
        height: 4px;
        background-color: #f9cb42;
    }

    @media only screen and ${device.mobileL} {
        margin: 0;
    }
`;

const StyledAuthWrapper = styled(SectionBlock)`
    padding-top: 48px;
    background-color: #4f7ac7;
`


const FeatureBlockWrapper = styled.div`
    display: flex;

    @media only screen and ${device.mobileL} {
        display: inline;
    }
`;

const FeatureBlock = styled.div`
    text-align: center;
    
    @media only screen and ${device.mobileL} {
        margin: 0 14px;
    }

    img {
        @media only screen and ${device.mobileL} {
            width: 45px;
        }
    }
`;

const StyledHeading = styled(Heading)`
    color: #343434;
    text-align: center;
    width: 100%;
`;

const StyledText = styled(Text)`
    text-align: left;
    color: #343434;

    @media only screen and ${device.mobileL} {
        text-align: center;
    }
`;

const Identity = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <StyledGradInnerWrapper>
                <DecoHeading heading="Security" desc="Identity & Access Management" icon={<SolutionSecurityIcon />} />
                <DecoDesc1>
                    Managing user identities and their rights to access resources throughout the identity life cycle is critical for effective identity and access management, in both our physical and logical worlds.
                    OPM, enables legitimated as well as approved users to access applications and data while protecting these assets against unauthorized access without interfering with productivity while balancing the security risks with the cost of acquisition and management.
                </DecoDesc1>
                <br />
                <img src={iden1} width="100%" style={{ maxWidth: '1000px', objectFit: 'contain', padding: '0 16px' }} />
            </StyledGradInnerWrapper>
            <TopButton marginLeft={15} marginLeftMobileL={15} marginTop={48} marginTopMobileL={24} />
            <StyledHeading>Features</StyledHeading>
            <StyledInnerWrapper>
                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <FeatureBlockWrapper>

                        <FeatureBlock>

                            <img src={idenacc1} style={{
                                alignSelf: 'center',
                                marginTop: `32px`,
                                marginBottom: `15px`
                            }} />
                            <StyledText>OPM enables organizations to provide authorized user access to systems, protfolio of products and services</StyledText>
                        </FeatureBlock>

                        <FeatureBlock>

                            <img src={idenacc2} style={{
                                alignSelf: 'center',
                                marginTop: `32px`,
                                marginBottom: `15px`
                            }} />
                            <StyledText>Provides efficient management of the entire identity lifecycle: assessing, planning, implementing, auditing, monitoring and maintaining identities and access privileges</StyledText>
                        </FeatureBlock>

                        <FeatureBlock>

                            <img src={idenacc3} style={{
                                alignSelf: 'center',
                                marginTop: `32px`,
                                marginBottom: `22px`
                            }} />
                            <StyledText>Identity life-cycle management, with user self-care enrollment, proofing, provisioning, recertification and de-provisioning</StyledText>
                        </FeatureBlock>

                        <FeatureBlock>

                            <img src={idenacc4} style={{
                                alignSelf: 'center',
                                marginTop: `32px`,
                                marginBottom: `15px`
                            }} />
                            <StyledText>Identity control, including access and privacy control, role management, single sign-on (SSO) and auditing</StyledText>
                        </FeatureBlock>

                    </FeatureBlockWrapper >


                </div>
            </StyledInnerWrapper>

            <AuthDescBox>
                Access authorization provides timely access throughout the user's life cycle across multiple environments and security domains while enforcing security policies and protecting against external threats with:
            </AuthDescBox>

            <StyledAuthWrapper>
                <AuthBlock icon={<SolutionAuth1Icon />} text="Centralized control for consistent execution of security policies across multiple applications and users" />
                <AuthBlock icon={<SolutionAuth2Icon />} text="Automation with a policy-based security infrastructure guided by IT requirements and business goals" />
                <AuthBlock icon={<SolutionAuth3Icon />} text="Single Sign-On (SSO) to improve the user experience and reduce help-desk costs" />
                <AuthBlock icon={<SolutionAuth4Icon />} text="Integration of access and identity management within one infrastructure environment" />
                <AuthBlock icon={<SolutionAuth5Icon />} text="Identity federation to share user authentication and attribute information between trusted Web services applications" />
                <AuthBlock icon={<SolutionAuth6Icon />} text="Multi-factor authentication to enhance security" />
            </StyledAuthWrapper>

            {/* <img src={first} style={{ width: `100%`, alignSelf: `center` }} /> */}

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
            <div style={{ display: `flex`, alignItems: `start`, padding: '0px 16px 80px', alignSelf: `center`, marginRight: 'auto' }}>
                <img src={idenacc11} style={{ width: `100%`, margin: `auto`, maxWidth: '800px', alignSelf: `center`, marginTop: `30px` }} alt="" />
            </div>

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Products#PRIME'><img src={primeLogo} alt="" height="50" /></a>
                    <a href='/pages/Products#LDAP'><img src={ldapLogo} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a target='_blank' href='https://www.microfocus.com/en-us/cyberres/identity-access-management/identity-manager'><img src={microLogo} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default Identity
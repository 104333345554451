import styled, { css, withTheme } from 'styled-components';
import { device, Heading, Text } from './common.styles';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 64px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        display: inline;
    }

    ${props => !props.imageFirst && css`
        flex-direction: row-reverse;
    `}
`;

const Left = styled.div`
    width: 50%;
    padding: 8px;
    box-sizing: border-box;

    img {
        width: 100%;
    }

    @media only screen and ${device.mobileL} {
        width: 100%;
    }
`;

const Right = styled.div`
    width: 50%;
    padding: 8px;
    box-sizing: border-box;

    @media only screen and ${device.mobileL} {
        width: 100%;
    }

    ul {
        padding-left: 24px;
        box-sizing: border-box;
        line-height: 1.8;
    }
`;

const StyledHeading = styled(Heading)`
    font-family: AB;
    font-weight: bold;
    padding: 8px;
    box-sizing: border-box;
    text-align: ${props => props.titleAlign};
    color: #343434;
`;

const StyledText = styled(Text)`
    padding: 8px;
    box-sizing: border-box;
    color: #343434;
    white-space: pre-wrap;
`;

const ImageDescBlock = ({ image, title, titleAlign = 'left', desc, imageFirst = true, children }) => (
    <Wrapper imageFirst={imageFirst}>
        <Left>
            <img src={image} alt="" />
        </Left>
        <Right>
            {title && <StyledHeading titleAlign={titleAlign}>{title}</StyledHeading>}
            <StyledText>{desc}</StyledText>
            {children}
        </Right>
    </Wrapper>
);

export default withTheme(ImageDescBlock);
import styled from "styled-components";
import { device } from "./common.styles";

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: calc(100% + 300px);
    margin-left: -300px;
    padding-left: 300px;
`;

const FullWidthSection = ({ className, children }) => (
    <Wrapper className={className}>{children}</Wrapper>
);

export default FullWidthSection;
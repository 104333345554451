import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;
`;

const ServiceCSIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg id="Isolation_Mode" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.43 18.42">
      <path d="M10.35,119.05H2a2,2,0,0,0-2,2v6.85a2,2,0,0,0,2,2h.3v1.45a.47.47,0,0,0,.47.46.45.45,0,0,0,.32-.14l1.77-1.77h5.51a2,2,0,0,0,2-2V121a2,2,0,0,0-2-2Zm0,9.89H4.65a.45.45,0,0,0-.33.14l-1.11,1.11v-.79a.47.47,0,0,0-.47-.46H2a1.06,1.06,0,0,1-1.06-1.06V121A1.07,1.07,0,0,1,2,120h8.37A1.08,1.08,0,0,1,11.42,121v6.85A1.07,1.07,0,0,1,10.35,128.94Z" transform="translate(0 -113.34)" />
      <path d="M3.51,123.71a.75.75,0,1,0,0,1.49.75.75,0,0,0,0-1.49Z" transform="translate(0 -113.34)" />
      <path d="M6.17,123.71a.75.75,0,1,0,.75.75h0A.76.76,0,0,0,6.17,123.71Z" transform="translate(0 -113.34)" />
      <path d="M8.83,123.71a.75.75,0,0,0-.74.75.75.75,0,0,0,1.49,0A.75.75,0,0,0,8.83,123.71Z" transform="translate(0 -113.34)" />
      <path d="M16.44,113.34H8.07a2,2,0,0,0-2,2v3.05a.46.46,0,0,0,.46.46.47.47,0,0,0,.46-.46v-3.05a1.07,1.07,0,0,1,1.06-1.06h8.37a1.06,1.06,0,0,1,1.06,1.06v6.85a1.05,1.05,0,0,1-1.06,1.06h-.76a.47.47,0,0,0-.46.47v.79l-1.11-1.12a.52.52,0,0,0-.33-.14H13a.47.47,0,0,0-.46.47.46.46,0,0,0,.46.46h.57l1.77,1.76a.43.43,0,0,0,.32.14.46.46,0,0,0,.46-.46h0v-1.44h.3a2,2,0,0,0,2-2v-6.85a2,2,0,0,0-2-2Z" transform="translate(0 -113.34)" />
    </svg>
  </IconWrapper>
);

export default ServiceCSIcon;




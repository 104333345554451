import React from 'react'


import moreInfo from '../../../assets/moreInfo.png'

import alauda from '../../../assets/alauda.png'

import styled from 'styled-components'
import { Heading, InnerWrapper, Text, device } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import TopButton from '../../../components/TopButton/TopButton'
import './alauda.css'


const Section = styled.div`
    text-align: center;
`;

const MoreInfoImg = styled.img`
    width: 383.1px;

    @media only screen and ${device.mobileL} {
        width: 170px;
    }
`;

const AlaudaImgContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    object-fit: contain;

    img {
        width: 252px;

        @media only screen and ${device.mobileL} {
            width: 84px;
        }
    }
`;

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
    padding: 16px;
`;

const StyledHeading = styled(Heading)`
    color: #343434;
`;

const StyledText = styled(Text)`
    color: #343434;
`;

const StyledContentTitle = styled(StyledText)`
    font-family: AB;
    font-weight: bold;
    color: #00b0bd;
    margin-top: 30px;
`;

const Alauda = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <DecoHeading heading="Alauda" />
            <AlaudaImgContainer><img src={alauda} alt="" /></AlaudaImgContainer>
            <DecoDesc1>
                With our Enterprise Cloud Native Success Platform, Alauda focuses on delivering cloud native capabilities and best practices to help enterprises accelerate digital transformation.
            </DecoDesc1>
            <StyledInnerWrapper>

                <div>
                    <StyledHeading style={{ marginTop: `30px`, textAlign: 'center' }}>Maximize Developer Productivity</StyledHeading>
                    <StyledContentTitle>DevOps Toolchain Integration</StyledContentTitle>
                    <StyledText>
                        Freely integrates existing DevOps tools that developers love to use
                    </StyledText>
                    <StyledContentTitle>DevOps Processes Orchestration</StyledContentTitle>
                    <StyledText>
                        Fully customizable DevOps processes to cater to the needs of organizations of different sizes and DevOps maturity
                    </StyledText>
                    <StyledContentTitle>Designed for Cloud Native</StyledContentTitle>
                    <StyledText>
                        Designed and optimized for containerized workflows and cloud native development processes
                    </StyledText>
                    <StyledContentTitle>Simplify Service Mech</StyledContentTitle>
                    <StyledText>
                        Adopt microservices and serverless design patterns
                    </StyledText>
                </div>


                <div>
                    <StyledHeading style={{ marginTop: `30px`, textAlign: 'center' }}>Achieve Operational Excellence</StyledHeading>
                    <StyledContentTitle>Hybrid & Multi-Cloud</StyledContentTitle>
                    <StyledText>
                        Manage on-prem K8s clusters as well as public cloud managed K8s clusters, such as EKS and AKS
                    </StyledText>
                    <StyledContentTitle>Unified Control Plane</StyledContentTitle>
                    <StyledText>
                        End-to-end observability for hybrid and multi-cloud to improve site reliability
                    </StyledText>
                    <StyledContentTitle>Full Distributed Cloud Stack</StyledContentTitle>
                    <StyledText>
                        A complete distributed cloud stack: public cloud, data centers & edge
                    </StyledText>
                    <StyledContentTitle>Cloud Native Data Services</StyledContentTitle>
                    <StyledText>
                        A fully integrated suite of cloud native data services that is enterprise-ready out of the box
                    </StyledText>
                </div>
                <br/>
                <Section>
                    <br />
                    <a target='_blank' href='https://www.alauda.io/'> <MoreInfoImg src={moreInfo} alt="" /></a>
                    <br />
                </Section>

                <br />
            </StyledInnerWrapper>

            <TopButton />

        </div >
    )
}

export default Alauda
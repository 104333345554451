import React from 'react';
import { Element } from 'react-scroll';
import IBroker from '../iBroker/IBroker';
import './ProductContent.css';



import ITrading from '../iTrading/ITrading';
import LDapProtext from '../LdapProtect/LDapProtect';
import Prime from '../Prime/Prime';
// import LDapProtect from '../LdapProtect/LDapProtect';
import CMSmart from '../CMSmart/CMSmart';
import Digittrade from '../Digittrade/Digittrade';
import IStorage from '../iStorage/IStorage';
import NFontDisable from '../nFomtDisable/NFontDisable';
import NFontPwd from '../nFontPwd/NFontPwd';
import NFontWPC from '../nFontWPC/NFontWpc';
import Alauda from '../Alauda/Alauda';

// import ConsultingService from '../consulting Services/ConsultingService';

import ProductCMSmartIcon from '../../../assets/ProductCMSmartIcon';
import ProductDigitTradeIcon from '../../../assets/ProductDigitTradeIcon';
import ProductiBrokersIcon from '../../../assets/ProductiBrokersIcon';
import ProductiStorageIcon from '../../../assets/ProductiStorageIcon';
import ProductiTradingIcon from '../../../assets/ProductiTradingIcon';
import ProductLDAPProtectIcon from '../../../assets/ProductLDAPProtectIcon';
import ProductnFrontAccDisablerIcon from '../../../assets/ProductnFrontAccDisablerIcon';
import ProductnFrontPwFilterIcon from '../../../assets/ProductnFrontPwFilterIcon';
import ProductnFrontWebPwChangeIcon from '../../../assets/ProductnFrontWebPwChangeIcon';
import ProductPrimeIcon from '../../../assets/ProductPrimeIcon';
import SideMenuContent from '../../../SideMenuContent';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../common.styles';
import AlaudaIcon from '../../../assets/AlaudaIcon';

const SECTIONS = {
  IBROKERS: 'IBROKERS',
  ITRADING: 'ITRADING',
  PRIME: 'PRIME',
  LDAP_PROTECT: 'LDAP_PROTECT',
  CMSMART: 'CMSMART',
  ISTORAGE: 'ISTORAGE',
  DIGITTRADE: 'DIGITTRADE',
  NFRONT_PASSWORD_FILTER: 'NFRONT_PASSWORD_FILTER',
  NFRONT_WEB_PASSWORD_CHANGE: 'NFRONT_WEB_PASSWORD_CHANGE',
  NFRONT_ACCOUNT_DISABLER: 'NFRONT_ACCOUNT_DISABLER',
  ALAUDA: 'ALAUDA',
}

const menuItems = [
  { label: 'iBrokers', icon: <ProductiBrokersIcon />, element: SECTIONS.IBROKERS },
  { label: 'iTrading', icon: <ProductiTradingIcon />, element: SECTIONS.ITRADING },
  { label: 'PRIME', icon: <ProductPrimeIcon />, element: SECTIONS.PRIME },
  { label: 'LDAP PROTECT', icon: <ProductLDAPProtectIcon />, element: SECTIONS.LDAP_PROTECT },
  { label: 'CMSmart', icon: <ProductCMSmartIcon />, element: SECTIONS.CMSMART },
  { label: 'iStorage', icon: <ProductiStorageIcon />, element: SECTIONS.ISTORAGE },
  { label: 'DIGITTRADE', icon: <ProductDigitTradeIcon />, element: SECTIONS.DIGITTRADE },
  { label: 'nFront Password Filter', icon: <ProductnFrontPwFilterIcon />, element: SECTIONS.NFRONT_PASSWORD_FILTER },
  { label: 'nFront Web Password Change', icon: <ProductnFrontWebPwChangeIcon />, element: SECTIONS.NFRONT_WEB_PASSWORD_CHANGE },
  { label: 'nFront Account Disabler', icon: <ProductnFrontAccDisablerIcon />, element: SECTIONS.NFRONT_ACCOUNT_DISABLER },
  { label: 'Alauda', icon: <AlaudaIcon />, element: SECTIONS.ALAUDA },
]


const ProductContent = () => {


  const contentHashes = {
    '#iBrokers': null,
    '#iTrading': null,
    '#PRIME': null,
    '#LDAP': null,
    '#CMSmart': null,
    '#iStorage': null,
    '#DIGITTRADE': null,
    '#nFront-Password-Filiter': null,
    '#nFront-Web-Password-Change': null,
    '#nFront-Account-Disabler': null,
    '#Alauda': null,
  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  const refreshScroll = () => {
    var hash = window.location.hash;
    if (hash && contentHashes[hash] instanceof HTMLElement) {
      if (isMobile) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition = contentHashes[hash].getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({ top: offsetPosition });
        }, 100);
      } else {
        contentHashes[hash].scrollIntoView();
      }
    }
  }

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      refreshScroll();
    }
  };

  return (
    <div>
      <SideMenuContent menuTitle="Products" menuItems={menuItems}>

        <div ref={(v) => { contentHashes[`#iBrokers`] = v; }}></div>


        <Element style={{ scrollMarginTop: '80px' }} name={SECTIONS.IBROKERS}>
          <IBroker style={{ scrollMarginTop: '80px' }} />
        </Element>
        <div ref={(v) => { contentHashes[`#iTrading`] = v; }}></div>
        <Element name={SECTIONS.ITRADING}>
          <ITrading />
        </Element>
        <div ref={(v) => { contentHashes[`#PRIME`] = v; }}></div>
        <Element name={SECTIONS.PRIME}>
          <Prime />
        </Element>
        <div ref={(v) => { contentHashes[`#LDAP`] = v; }}></div>
        <Element name={SECTIONS.LDAP_PROTECT}>
          <LDapProtext />
        </Element>
        <div ref={(v) => { contentHashes[`#CMSmart`] = v; }}></div>
        <Element name={SECTIONS.CMSMART}>
          <CMSmart />
        </Element>
        <div ref={(v) => { contentHashes[`#iStorage`] = v; }}></div>
        <Element name={SECTIONS.ISTORAGE}>
          <IStorage />
        </Element>
        <div ref={(v) => { contentHashes[`#DIGITTRADE`] = v; }}></div>
        <Element name={SECTIONS.DIGITTRADE}>
          <Digittrade />
        </Element>
        <div ref={(v) => { contentHashes[`#nFront-Password-Filiter`] = v; }}></div>
        <Element name={SECTIONS.NFRONT_PASSWORD_FILTER}>
          <NFontPwd />
        </Element>
        <div ref={(v) => { contentHashes[`#nFront-Web-Password-Change`] = v; }}></div>
        <Element name={SECTIONS.NFRONT_WEB_PASSWORD_CHANGE}>
          <NFontWPC />
        </Element>
        <div ref={(v) => { contentHashes[`#nFront-Account-Disabler`] = v; }}></div>
        <Element name={SECTIONS.NFRONT_ACCOUNT_DISABLER}>
          <NFontDisable />
        </Element>
        <div ref={(v) => { contentHashes[`#Alauda`] = v; }}></div>
        <Element name={SECTIONS.ALAUDA}>
          <Alauda />
        </Element>
      </SideMenuContent>
    </div>
  )
}

export default ProductContent;
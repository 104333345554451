import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const AlaudaIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 600 700">
      <path d="M0 307C0 137.449 137.449 0 307 0s307 137.449 307 307v307H483.5V318c1.167-61-31.3-183-170.5-183-107.821 0-146.538 65.338-160.375 114.938L97.5 277.5l46.97 36.492c-1.445 46.487 7.363 212.794 278.495 300.008H307C137.449 614 0 476.551 0 307Z"/>
      <path d="M240.5 353c19.606 0 35.5-15.894 35.5-35.5S260.106 282 240.5 282 205 297.894 205 317.5s15.894 35.5 35.5 35.5Z"/>
    </svg>
  </IconWrapper>
);

export default AlaudaIcon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductiStorageIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.01 16.17">
      <path d="M51.75,528.56a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v3.61a.5.5,0,0,0,.5.5h16a.5.5,0,0,0,.5-.5Zm-8.88,2.6H36.19v-1.51h6.68Zm3.11.43a1.22,1.22,0,1,1,1.21-1.22h0A1.22,1.22,0,0,1,46,531.59Zm3,0a1.22,1.22,0,1,1,1.21-1.22h0A1.22,1.22,0,0,1,49,531.59Zm2.8-8.88a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v3.61a.5.5,0,0,0,.5.5h16a.5.5,0,0,0,.5-.5Zm-8.88,2.6H36.19V523.8h6.68Zm3.11.47a1.22,1.22,0,1,1,1.21-1.22h0A1.21,1.21,0,0,1,46,525.77Zm3,0a1.22,1.22,0,1,1,1.21-1.22h0A1.21,1.21,0,0,1,49,525.77Zm2.8-8.78a.5.5,0,0,0-.5-.5h-16a.5.5,0,0,0-.5.5v3.61a.5.5,0,0,0,.5.5h16a.5.5,0,0,0,.5-.5Zm-8.88,2.53H36.19V518h6.68ZM46,520a1.22,1.22,0,1,1,1.21-1.22h0A1.21,1.21,0,0,1,46,520Zm3,0a1.22,1.22,0,1,1,1.21-1.22h0A1.21,1.21,0,0,1,49,520Z" transform="translate(-34.75 -516.5)" />
    </svg>
  </IconWrapper>
);

export default ProductiStorageIcon;




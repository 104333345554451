import React from 'react'
import { Helmet } from "react-helmet";

import { Footer, Partner, FeatureList, WhatGPT3, Header, Features, Solution } from '../../containers';

import navBg from '../../assets/navBg.png'
import OPMLogo from '../../assets/OPMLogo';
import styled from 'styled-components';
import { device } from '../../common.styles';
import { useMediaQuery } from 'react-responsive';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AL;
  font-size: 88px;
  color: #343434;
  line-height: 1.26;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Slogan2 = styled.div`
  font-family: AB;
  font-size: 88px;
  line-height: 1.26;
  color: #343434;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const Home = () => {

  const contentHashes = {
    '#WhyOPM': null,
    '#Services': null,
    '#Products': null,
    '#Solution': null,
    '#BusinessPartner': null,
  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  const refreshScroll = () => {
    var hash = window.location.hash;
    if (hash && contentHashes[hash] instanceof HTMLElement) {
      if (isMobile) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition = contentHashes[hash].getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({ top: offsetPosition });
        }, 100);
      } else {
        contentHashes[hash].scrollIntoView();
      }
    }
  }

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      refreshScroll();
    }
  };

  return (
    <>
      <div className='App'>
        {/* <div className="gradient__bg"> */}

        <Helmet>
          <meta charSet="utf-8" />
          <title>Welcome to OPM System Company Limited</title>
          <meta name="Home page" content="opm Home page" />
        </Helmet>
        <Header bgImg={navBg}>
          <StyledOPMLogo />
          <Slogan1>WE PARTNER FOR</Slogan1>
          <Slogan2>A BRIGHT FUTURE</Slogan2>
        </Header>
        {/* <Navbar /> */}
        {/* <MiniNavbar /> */}

        <div ref={(v) => { contentHashes[`#WhyOPM`] = v; }}></div>
        <Features contentHashes={contentHashes} />
        {/* <FeatureList /> */}

        <div ref={(v) => { contentHashes[`#Solution`] = v; }}></div>
        <Solution />
        <div ref={(v) => { contentHashes[`#BusinessPartner`] = v; }}></div>
        <Partner />
        <Footer />

        {/* </div> */}
      </div>
    </>

  )
}

export default Home
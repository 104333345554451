import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 80px;
  height: 80px;

  @media only screen and ${device.mobileL} {
    width: 28px;
    height: 28px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth3Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 133.016 133.016">
      <g transform="translate(-658.254 -3077.664)">
        <path d="M739.031 3143.067c-.84-.445-2.191-.983-3.9-1.664a67.355 67.355 0 0 1-10.757-5.059 4.481 4.481 0 0 1-2.255-4.7c.388-1.52 1.813-2.477 3.911-2.623 5.684-.4 14.206 2.415 14.291 2.444a1 1 0 0 0 1.292-.73l2.173-9.672a1 1 0 0 0-.437-1.061c-3-1.92-9.756-2.754-12.236-3v-6.983a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v8.2c-13.3 3.132-15.192 11.756-14.4 18.507.905 7.7 10.055 12.95 17.617 15.112 6.12 1.749 7.677 3.2 6.938 6.46-.773 3.428-8 3.925-12.7 2.957a35.535 35.535 0 0 1-8.5-3.063 1 1 0 0 0-1.427.573l-3.4 10.526a1 1 0 0 0 .425 1.157c4.98 3.083 12.852 3.987 15.573 4.214v7.658a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1.006l-.043-8.431c6.815-.983 14.155-6.584 15-15.572.982-10.374-5.796-14.519-7.165-15.244z" />
      </g>
    </svg>

  </IconWrapper>
);

export default SolutionAuth3Icon;




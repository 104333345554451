import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductiTradingIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 17.01 18.32">
      <path d="M50.57,214.59H38.82a1.18,1.18,0,0,0-1.18,1.18v16a1.18,1.18,0,0,0,1.18,1.18H50.57a1.18,1.18,0,0,0,1.18-1.18h0v-16A1.18,1.18,0,0,0,50.57,214.59Zm-10.26,7a.73.73,0,0,1,1,.38.71.71,0,0,0,.72.42.3.3,0,0,0,.3-.27.36.36,0,0,0-.19-.38l-1.2-.62a1.83,1.83,0,0,1-1-2,1.78,1.78,0,0,1,1.29-1.41v-.11a.74.74,0,1,1,1.48,0h0v.27A2.11,2.11,0,0,1,43.77,219a.73.73,0,0,1-.35,1,.74.74,0,0,1-1-.36l0,0a.72.72,0,0,0-.71-.43.31.31,0,0,0-.3.27.34.34,0,0,0,.18.39l1.2.62a1.82,1.82,0,0,1,1,1.92,1.75,1.75,0,0,1-1,1.36v.16a.74.74,0,0,1-1.48,0v-.11a2.18,2.18,0,0,1-1.32-1.2.74.74,0,0,1,.39-1Zm4.94,9H40.81a.74.74,0,1,1,0-1.48h4.44a.74.74,0,0,1,0,1.48Zm3.54-3h-8a.74.74,0,0,1,0-1.47h8a.74.74,0,1,1,0,1.47Zm0-3H45.92a.74.74,0,1,1,0-1.48h2.87a.74.74,0,0,1,0,1.48Zm0-2.92H45.92a.74.74,0,1,1,0-1.47h2.87a.74.74,0,1,1,0,1.47Zm0-2.95H45.92a.74.74,0,1,1,0-1.48h2.87a.74.74,0,0,1,0,1.48Z" transform="translate(-34.74 -214.59)" />
      <path d="M35.48,216.06a.74.74,0,0,0-.73.74h0v13.9a.74.74,0,0,0,1.47.05V216.8a.74.74,0,0,0-.73-.74Z" transform="translate(-34.74 -214.59)" />
    </svg>
  </IconWrapper>
);

export default ProductiTradingIcon;




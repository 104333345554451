import React from 'react';
import styled from 'styled-components';
import { device, Text } from './common.styles';
import QuoteLeft from './QuoteLeft';
import QuoteRight from './QuoteRight';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content:center;

  @media only screen and ${device.mobileL} {
    display: inline;
  }
`;

const Content = styled(Text)`
  display: flex;
  position: relative;
  padding: 16px 0px;
  box-sizing: border-box;
  color: #c2c2c2;

  @media only screen and ${device.mobileL} {
    padding: 16px 40px;
  }

  svg {
    width: 50px;
    
    @media only screen and ${device.mobileL} {
      width: 13px;
    }
  }

  div {
    padding-top: 22px;
    
    @media only screen and ${device.mobileL} {
      padding-top: 5px;
    }
  }
`;

const DecoDesc1 = ({ className, logo, children }) => (
  <Wrapper className={className}>
    {logo}
    <Content>
      <QuoteLeft />
      <div>{children}</div>
      <QuoteRight />
    </Content>
  </Wrapper>
);

export default DecoDesc1;




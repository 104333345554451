import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 50px;
  height: 50px;

  @media only screen and ${device.mobileL} {
    width: 18px;
    height: 18px;
  }
  
  svg {
    fill:#343434;
  }
`;

const SolutionAuth2Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 72.313 72.304">
      <path d="m1307.641 2931.439-9.014-1.405a26.444 26.444 0 0 0-2.886-6.945l5.391-7.371a1.59 1.59 0 0 0-.159-2.005l-5.232-5.227a1.573 1.573 0 0 0-1.993-.157l-7.376 5.391a26.15 26.15 0 0 0-6.954-2.883l-1.393-9.015a1.6 1.6 0 0 0-1.532-1.312h-7.4a1.6 1.6 0 0 0-1.527 1.312l-1.391 9.015a26.094 26.094 0 0 0-6.956 2.883l-7.373-5.391a1.589 1.589 0 0 0-2 .157l-5.234 5.227a1.6 1.6 0 0 0-.151 2.005l5.39 7.371a26.443 26.443 0 0 0-2.884 6.945l-9.022 1.405a1.607 1.607 0 0 0-1.3 1.527v7.4a1.591 1.591 0 0 0 1.3 1.523l9.022 1.4a26.507 26.507 0 0 0 2.884 6.952l-5.39 7.366a1.605 1.605 0 0 0 .151 2.012l5.234 5.229a1.594 1.594 0 0 0 2 .153l7.373-5.391a26.563 26.563 0 0 0 6.956 2.885l1.391 9.013a1.594 1.594 0 0 0 1.527 1.3h7.4a1.6 1.6 0 0 0 1.532-1.3l1.393-9.013a26.621 26.621 0 0 0 6.954-2.885l7.376 5.391a1.6 1.6 0 0 0 2-.153l5.223-5.238a1.587 1.587 0 0 0 .159-2l-5.391-7.366a26.508 26.508 0 0 0 2.886-6.952l9.014-1.4a1.584 1.584 0 0 0 1.315-1.523v-7.4a1.6 1.6 0 0 0-1.313-1.53zM1272.793 2950a13.333 13.333 0 1 1 13.331-13.323 13.329 13.329 0 0 1-13.331 13.323z" transform="translate(-1236.643 -2900.51)" />
    </svg>
  </IconWrapper>
);

export default SolutionAuth2Icon;




import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";
import letter from '../../../assets/letter.png';
import arrow from '../../../assets/letter_arrow.png';
import sendSuccess from '../../../assets/send_done.png';
import './ContactUsContent.css';
import styled from 'styled-components';
import { device, SectionHeading } from '../../../common.styles';
import { useMediaQuery } from 'react-responsive';


const contentCss = `.content {
  flex-grow: 1;
}`;



// function onMenuClick() {

//   const [currentIndex, setCurrentIndex] = React.useState(0)

// }


const ContactUsContent = () => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const form = useRef();

  const StyledSectionHeading = styled(SectionHeading)`
  font-family: AR;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
`

  const sendEmail = async (e) => {
    // e.preventDefault();

    // const { name, email, phone, message } = e.target.elements
    let details = {
      first_name:form.current.first_name.value,
      user_name:form.current.user_name.value,
      user_email:form.current.user_email.value,
      message:form.current.message.value,
    }
    console.log('here: ', details)
     await fetch("https://siemens.opm.com.hk/opm-website-email-backend/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    }).then(
        (result) => {
          setIsSuccess(true)
        },
        (error) => {
          setIsSuccess(false)
        }
      );
    
    // emailjs
    //   .sendForm(
    //     "service_2iuv75w",
    //     "template_aio7o4o",
    //     form.current,
    //     "GeelwMzU76Z21JZC7"
    //   )
    //   .then(
    //     (result) => {
    //       setIsSuccess(true)
    //     },
    //     (error) => {
    //       setIsSuccess(false)
    //     }
    //   );
  };

  const contentHashes = {
    '#Message': null,
  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  const refreshScroll = () => {
    var hash = window.location.hash;
    if (hash && contentHashes[hash] instanceof HTMLElement) {
      if (isMobile) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition = contentHashes[hash].getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({ top: offsetPosition });
        }, 100);
      } else {
        contentHashes[hash].scrollIntoView();
      }
    }
  }

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      refreshScroll();
    }
  };

  return (

    <div className='contentus-section section'>
      <div ref={(v) => { contentHashes[`#Message`] = v; }}></div>
      <div class="contactusBg">
        <div class="left-item">
          <img src={letter} />
        </div>
        <div class="right-item">
          <StyledSectionHeading>Get In Touch</StyledSectionHeading>
          <div>
            <div class="container">
              <form ref={form} onSubmit={sendEmail}>
                <div class="row">
                  <div>
                    <input type="text" id="lname" name="first_name" placeholder="First Name" />
                  </div>
                </div>

                <div class="row">
                  <input type="text" id="lname" name="user_name" placeholder="Last Name" />
                </div>

                <div class="row">
                  <input type="text" id="lname" name="user_email" placeholder="Email" />
                </div>

                <div class="row">
                  <textarea id="subject" name="message" placeholder="Message" style={{ height: `200px` }}></textarea>
                </div>

                {/* <input className='submitBtnStyle' type="submit" value="Send" >
                  </input> */}

                <div className='submitBtn' onClick={() => { sendEmail() }} style={{ justifyContent: `space-between` }}>

                  <div style={{ display: isSuccess ? "none" : "flex", flexDirection: `row`, justifyContent: `space-between`, marginTop: `30px` }}>
                    <div></div>
                    <p className='sendBtn'>Send Message</p>
                    <img src={arrow} className="letter_arrow_submit" />
                  </div>

                </div>
                <div style={{ display: isSuccess ? "flex" : "none", justifyContent: `center`, marginTop: `30px`, height: '60px' }}>
                  <img src={sendSuccess} alt="success" />
                </div>


              </form>
              {!isMobile && <img src={arrow} className="letter_arrow" />}
            </div>
          </div>
        </div>
      </div >

    </div >
  )
}

export default ContactUsContent

import React from 'react';
import { Helmet } from 'react-helmet';

import '../Services/ConsultingServices/ConsultingService.css';
import OpmContent from './OpmContent/OpmContent';


import styled from 'styled-components';
import "../../assets/css/Common.css";
import OPMLogo from '../../assets/OPMLogo';
import opmBg from '../../assets/opm_bg.png';
import { device } from '../../common.styles';
import { Footer, Header } from '../../containers';

const StyledOPMLogo = styled(OPMLogo)`
  svg {
    width: 300px;
  }

  @media only screen and ${device.mobileL} {
    display: none;
  }
`;

const Slogan1 = styled.div`
  font-family: AL;
  font-size: 88px;
  color: white;
  
  @media only screen and ${device.mobileL} {
    font-size: 24px;
  }
`

const OPM = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Why OPM -  Welcome to OPM System Company Limited</title>
        <meta name="Why OPM" content="OPM - Why OPM" />
      </Helmet>
      <Header bgImg={opmBg} tone="dark">
        <StyledOPMLogo />
        <Slogan1>
          Why OPM
        </Slogan1>
      </Header>
      <OpmContent />
      <Footer bgcolor='#7c98b4' />
    </>
  )
}

export default OPM
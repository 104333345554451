import React from 'react'
import pdfDownloadIcon from '../../../../assets/pdf_download_icon.svg'





import blackberryLogo from '../../../../assets/blackberry.png'
import bl from '../../../../assets/bottomline.png'
import cslLogo from '../../../../assets/csl.png'
import enterprise_solution from '../../../../assets/enterprise_solution.png'
import serviceAd from '../../../../assets/service_ad.png'
import serviceCs from '../../../../assets/service_cs.png'
import serviceTs from '../../../../assets/service_ts.png'

import styled from 'styled-components'
import Pdf from '../../../../assets/pdf/EndUser.pdf'
import SolutionEndUserIcon from '../../../../assets/SolutionEndUserIcon'
import { device, Heading, InnerWrapper, Text } from '../../../../common.styles'
import DecoDesc1 from '../../../../DecoDesc1'
import DecoHeading from '../../../../DecoHeading'
import SectionBlock from '../../../../SectionBlock'
import TopButton from '../../../../components/TopButton/TopButton'
import './MobileEnterpriseSolution.css'

const Features = styled.div`
    position: relative;
    width: 75%;
    margin: 32px auto;
`;

const FeaturesBorder = styled.div`
    border: 1px solid #4f7ac7;
    box-sizing: border-box;

    &.second {
        width: 66.666%;
    }

    &::before {
        position: absolute;
        top: 0px;
        left: 30%;
        width: 40%;
        height: 20px;
        border-top: 1px solid white;
        display: block;
        content: ' ';
    }

    div {
        box-sizing: border-box;

        &::before {
            position: absolute;
            top: -4px;
            left: 30%;
            width: 8px;
            height: 8px;
            background-color: #4f7ac7;
            border-radius: 50px;
            content: ' ';
        }

        &::after {
            position: absolute;
            top: -4px;
            right: 30%;
            width: 8px;
            height: 8px;
            background-color: #4f7ac7;
            border-radius: 50px;
            content: ' ';
        }
    }
`;

const FeaturesContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 32px;
    gap: 16px;

    img {
        object-fit: contain;
        width: 223px;

        @media only screen and ${device.mobileL} {
            width: 80px;
        }
    }

    @media only screen and ${device.mobileL} {
        margin: 32px 16px;
        flex-wrap: wrap;
    }
`;

const StyledText = styled(Text)`
    text-align: left;

    @media only screen and ${device.mobileL} {
        text-align: center;
    }
`;

const StyledHeading = styled(Heading)`
    position: absolute;
    top: -35px;
    width: 100%;
    text-align: center;

    @media only screen and ${device.mobileL} {
        top: -12px;
    }
`;

const MobileeSolution = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };
    return (
        <div>
            <InnerWrapper>
                <DecoHeading desc="Mobile Enterprise Solution" icon={<SolutionEndUserIcon />} />

                <DecoDesc1>
                    The challenge many organizations facing today is how to empower these mobile professionals and make them more productive while they are on the road. We provided solutions which allow businesses,
                    government agencies, and mobile workers to maximize business and personal productivity by providing secure, easy-to-use, and instant mobile access to data, applications, and services on hundreds of mobile devices.
                </DecoDesc1>

                <div className='applicationline'>
                    <img src={enterprise_solution} style={{ width: `75%` }} />
                </div>
                <br />
                <br />
                <br />
                <TopButton marginLeft={10} marginLeftMobileL={10} />
                <br />
                <br />
                <br />
                <Features>
                    <FeaturesBorder>
                        <StyledHeading>Features</StyledHeading>
                        <FeaturesContent>
                            <StyledText>OPM works closely with mobile partners providing mobile data management solution for popular handheld devices like iPhone, iPad, Android and BlackBerry so that they can access critical data anytime, anywhere securely. <br />With our dedicated IT certified experts who skilled in BlackBerry@Enterprise Server and GOOD Enterprise Server, OPM can provide you the most cost-effective Mobile Data Management Solution of your organization.</StyledText>
                            <a target='_blank' href={Pdf}><img src={pdfDownloadIcon} /></a>
                        </FeaturesContent>
                    </FeaturesBorder>
                </Features>

            </InnerWrapper>

            <SectionBlock>
                Related Products
                <div style={{ marginTop: `20px` }}>
                    <a target='_blank' href='https://www.hkcsl.com/'><img src={cslLogo} alt="" height="50" style={{ marginRight: '20px' }} /></a>
                    <a target='_blank' href='https://www.blackberry.com/cn/zh'><img src={blackberryLogo} alt="" height="50" /></a>
                </div>
                <div><img src={bl} alt="" style={{ width: `80%`, marginTop: `20px`, marginBottom: `20px`, objectFit: `contain` }} /></div>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default MobileeSolution
import React from 'react'





import cs from '../../../../assets/cs.png'
import serviceAd from '../../../../assets/service_ad.png'
import serviceCs from '../../../../assets/service_cs.png'
import serviceTs from '../../../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionAiIcon from '../../../../assets/SolutionAiIcon'
import { device, InnerWrapper } from '../../../../common.styles'
import DecoHeading1 from '../../../../DecoHeading'
import SectionBlock from '../../../../SectionBlock'
import TopButton from '../../../../components/TopButton/TopButton'
import './OCR.css'

const ContactUsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    @media only screen and ${device.mobileL} {
        height: 100px;
    }

    a {
        width: 380.9px;
        
        @media only screen and ${device.mobileL} {
            width: 169.34px;
        }
    }
`;

const Ocr = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading1 desc="Optical Character Recognition(OCR)" icon={<SolutionAiIcon />} />

                <ContactUsContainer>
                    <a href='/pages/ContactUs#Message'><img src={cs} style={{ width: `100%` }} /></a>
                </ContactUsContainer>
            </InnerWrapper>

            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default Ocr
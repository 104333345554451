import React from 'react'




import hsc from '../../../../assets/hcsLogo.png'
import serviceAd from '../../../../assets/service_ad.png'
import serviceCs from '../../../../assets/service_cs.png'
import serviceTs from '../../../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionIndustryIcon from '../../../../assets/SolutionIndustryIcon'
import { Heading, InnerWrapper, Text } from '../../../../common.styles'
import DecoHeading from '../../../../DecoHeading'
import SectionBlock from '../../../../SectionBlock'
import TopButton from '../../../../components/TopButton/TopButton'
import './HealthCareSolution.css'

const StyledHeading = styled(Heading)`
    color: #343434;
`;

const StyledText = styled(Text)`
    color: #343434;
`;

const HealthCareSolution = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <InnerWrapper>
                <DecoHeading heading="By Industry" desc="Health Care Solution" icon={<SolutionIndustryIcon />} />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '32px', margin: '32px' }}>
                    <StyledText>
                        <ul>
                            <li>Patient Management System</li>
                            <li>Pharmaceutical Dispensing System</li>
                            <li>Drug Allergy Alert System</li>
                            <li>Drug Inventory Management System</li>
                        </ul>
                    </StyledText>

                    <StyledText>
                        <ul>
                            {/* <ul style={{ margin: `10px 50px` }}> */}
                            <li>Out-patient Management System</li>
                            <li>Pharmaceutical Registration</li>
                            <li>Import and Export integration</li>
                        </ul>
                    </StyledText>
                </div>






                <div style={{ textAlign: `center`, margin: '0 0 16px' }}>


                    <StyledHeading style={{ marginBottom: `30px` }} className='iBroker_webview_preview_style'>Features</StyledHeading>

                    <StyledText>
                        OPM works closely with government of the design of the health care solution as well as the government monitoring organisation.
                    </StyledText>


                    <img style={{ width: `50%`, alignSelf: `center`, margin: `20px 0px` }} src={hsc} />

                </div>
            </InnerWrapper >


            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>

                </div>
            </SectionBlock>

            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />

        </div >
    )
}

export default HealthCareSolution
import React from 'react'




import cmsmartBanner from '../../../assets/cmsmart_banner.png'
import cmsmartFeatures from '../../../assets/cmsmart_features.png'
import square from '../../../assets/cmssmartsqureban.jpg'

import styled from 'styled-components'
import { device, Heading, InnerWrapper, Text } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import ITradingBenefitGrid from '../iTrading/ITradingBenefitGrid'
import TopButton from '../../../components/TopButton/TopButton'
import './CMSmart.css'

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const Section = styled.div`
    text-align: center;

    @media only screen and ${device.mobileL} {
        .square {
            width: 100%;
        }
    }
`;

const FeaturesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    img {
        object-fit: contain;
        height: 93.1px;

        @media only screen and ${device.mobileL} {
            height: 45px;
        }
    }
`;

const StyledFeaturesText = styled(Text)`
   color: #343434;
`;

const BenefitsBox = styled.div`
    width: 95%;
    margin: auto;
    padding: 16px;
    box-sizing: border-box;
`;

const StyledITradingBenefitGrid = styled(ITradingBenefitGrid)`
    // width: 25%;

    // @media only screen and ${device.mobileL} {
    //     width: 50%;
    // }
`;

const CMSmart = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div>
            <InnerWrapper>
                <DecoHeading heading="CMSmart" subheading="SME’s Best Virtual Data & PR Solution Web Management System" />
                <Section>
                    <br />
                    <img src={cmsmartBanner} alt="" width="60%" />
                    <br />
                </Section>
                <DecoDesc1>
                    It has integrated website set-up, management and content update functions. It's interface is designed by the professional OPM team, customised to suit your business needs. It's user-friendly and maintenance-free features enable you to manage your news release more effectively.
                </DecoDesc1>
                <Section>
                    <img className='square' src={square} alt="" />
                </Section>
                <br />
                <br />
                <TopButton />
                <Section>
                    <FeaturesBox>
                        <StyledHeading>
                            Features
                        </StyledHeading>
                        <StyledFeaturesText>Website set-up, management and content update</StyledFeaturesText>
                        <img src={cmsmartFeatures} alt="" />
                    </FeaturesBox>
                </Section>
                <br />
                <br />
                <br />
                <br />
                <Section>
                    <StyledHeading>
                        Benefits
                    </StyledHeading>
                    <BenefitsBox>
                        <StyledITradingBenefitGrid index={1} text="No programming and installation required" />
                        <StyledITradingBenefitGrid index={2} text="Auto image resize" />
                        <StyledITradingBenefitGrid index={3} text="High Quality Picture slide show" />
                        <StyledITradingBenefitGrid index={4} text="Cover all business sectors" />
                        <StyledITradingBenefitGrid index={5} text="Multi-lingual Support" />
                        <StyledITradingBenefitGrid index={6} text="User-friendly" />
                        <StyledITradingBenefitGrid index={7} text="Customizable" />
                    </BenefitsBox>
                </Section>
                <TopButton />
            </InnerWrapper>
        </div>
    )
}

export default CMSmart
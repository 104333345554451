import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
    margin: 0 4px;
`;

const SolutionAiIcon = ({ className, ...otherProps }) => (
    <IconWrapper className={className} {...otherProps}>
        <svg viewBox="0 0 21.23 21.96">
            <circle cx="7.5" cy="10.09" r="1.17" />
            <circle cx="13.72" cy="10.09" r="1.17" />
            <path d="M20,9.55a.15.15,0,0,1-.11-.15V9.1A4.12,4.12,0,0,0,16,4.76H12.56a.14.14,0,0,1-.14-.1A2,2,0,0,0,11.11,3.5.15.15,0,0,1,11,3.36V2.27A1.18,1.18,0,0,0,11.71.78,1.15,1.15,0,0,0,10.23.07a1.16,1.16,0,0,0,0,2.2V3.36a.16.16,0,0,1-.12.14,1.94,1.94,0,0,0-1.3,1.16.16.16,0,0,1-.14.1H5.37a4.1,4.1,0,0,0-4,4.17V9.4a.16.16,0,0,1-.12.15A1.52,1.52,0,0,0,0,11.38,1.54,1.54,0,0,0,1.2,12.55a.14.14,0,0,1,.12.14V13a4.1,4.1,0,0,0,3.87,4.33h5.19a.17.17,0,0,1,.14.07.16.16,0,0,1,0,.15L9.28,21.77a.16.16,0,0,0,.06.17.15.15,0,0,0,.18,0l6-4.59h.07A4.35,4.35,0,0,0,19.91,13v-.3a.16.16,0,0,1,.12-.15,1.54,1.54,0,0,0,0-3Zm-1.28,4.31a2.5,2.5,0,0,1-2.48,2.48H5a2.48,2.48,0,0,1-2.47-2.48V8.23A2.48,2.48,0,0,1,5,5.75h11.3a2.51,2.51,0,0,1,2.48,2.48Z" />
            <path d="M12,12.35H9.24a.16.16,0,0,0-.13.06.18.18,0,0,0,0,.14,1.55,1.55,0,0,0,3.08,0,.17.17,0,0,0-.14-.2Z" />
        </svg>
    </IconWrapper>
);

export default SolutionAiIcon;
import React from 'react'




import cmsLine from '../../../../assets/cmsLine.png'
import cmsshake from '../../../../assets/cmsshake.png'

import liveRight from '../../../../assets/live_right.png'
import liveStream from '../../../../assets/live_steam.png'
import serviceAd from '../../../../assets/service_ad.png'
import serviceCs from '../../../../assets/service_cs.png'
import serviceTs from '../../../../assets/service_ts.png'

import styled from 'styled-components'
import SolutionIndustryIcon from '../../../../assets/SolutionIndustryIcon'
import { device, Text } from '../../../../common.styles'
import DecoHeading from '../../../../DecoHeading'
import SectionBlock from '../../../../SectionBlock'
import TopButton from '../../../../components/TopButton/TopButton'
import './LiveStreaming.css'

const ShakeContainer = styled.div`
    text-align: center;
    margin: 30px 0 60px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;

    @media only screen and ${device.mobileL} {
        flex-wrap: wrap;
    }

    img {
        align-self: start;
        width: 100px;

        @media only screen and ${device.mobileL} {
            width: 45px;
        }
    }
`;

const LiveStreamImg = styled.img`
    max-width: 200px;

    @media only screen and ${device.mobileL} {
        width: 50%;
    }
`;

const LiveRight = styled.img`
    object-fit: contain;

    @media only screen and ${device.mobileL} {
        width: 20%;
    }
`;

const LiveSreaming = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <DecoHeading desc="Interactive Live Streaming System" icon={<SolutionIndustryIcon />} />


            <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, gap: '8px', padding: '0 16px' }}>
                <div style={{ display: `flex`, flexDirection: `column` }}>
                    <div className='quote'>

                        <LiveStreamImg src={liveStream} />



                    </div>

                    <div style={{ textAlign: `center`, margin: '16px 0' }}>
                        <Text>Live Streaming System to allow our client to broadcast the real life performance such as<br />live concert with the interaction with the public</Text>

                        <img src={cmsLine} style={{ alignSelf: `center`, justifyContent: `top`, width: `70%`, marginTop: `30px` }} />
                    </div>

                    <ShakeContainer>

                        <img src={cmsshake} />

                        <Text>OPM works closely with commercial company to design interactive live streaming<br />system to handle large amount of user with low latency. In the meantime to allow<br /> the public to interact with the performer.</Text>

                    </ShakeContainer>


                </div>

                <LiveRight src={liveRight} />
            </div>


            <SectionBlock>
                Related Services
                <div style={{ marginTop: `20px` }}>
                    <a href='/pages/Services#Application-Development'><img src={serviceAd} alt="" height="50" /></a>
                    <a href='/pages/Services#Consulting-Services'><img src={serviceCs} alt="" height="50" style={{ marginLeft: '16px', marginRight: '16px' }} /></a>
                    <a href='/pages/Services#Technical-Support'><img src={serviceTs} alt="" height="50" /></a>
                </div>
            </SectionBlock>
            <TopButton marginLeft={15} marginTop={30} marginTopMobileL={30} marginLeftMobileL={15} />
        </div>
    )
}

export default LiveSreaming
import styled from 'styled-components';
import { device, Text } from '../../../common.styles';

const Wrapper = styled.div`
    position: relative;
    padding: 16px 8px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: end;
    vertical-align: bottom;
    justify-content: center;
    /* height: 200px; */

   

    &:nth-child(3n) {
        .deco-right-line, .deco-circle {
            display: none;
        }
    }

    &:nth-child(n+4) {
        .deco-bottom-line, .deco-circle {
            display: none;
        }
    }
`;

const Content = styled.div`
    height: 100%;
`;

const DecoBottomLine = styled.div`
    position: absolute;
    bottom: 0;
    left: 16px;
    width: calc(100% - 32px);

    &::after {
        display: block;
        content: ' ';
        background-color: #4f7ac7;
        width: 100%;
        height: 1px;
    }
`;

const DecoRightLine = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    width: 1px;
    height: calc(100% - 50px);

    &::after {
        display: block;
        content: ' ';
        background-color: #4f7ac7;
        width: 100%;
        height: 100%;
    }
`;

const DecoCircle = styled.div`
    position: absolute;
    bottom: 3px;
    right: -3px;
    background-color: #4f7ac7;
    width: 6px;
    height: 6px;
    border-radius: 50px;
`;

const TextContainer = styled.div`
    display: flex;
    height: calc(100% - 66.962px);

    @media only screen and ${device.mobileL} {
        height: calc(100% - 22.321px);
    }
`;

const StyledText = styled(Text)`
    line-height: 1.5;
    margin: auto;
    box-sizing: border-box;
    padding: 8px;
    padding-bottom: 16px;
`;

const DecoImage = styled.div`
    object-fit: contain;
    display: flex;
    align-items: end;
    justify-content: center;
`

const ITradingKeyFeaturesGrid = ({ className, icon, text }) => (
    <Wrapper className={className}>
        <Content>
            <TextContainer><StyledText>{text}</StyledText></TextContainer>
            <DecoImage>{icon}</DecoImage>
            <DecoBottomLine className="deco-bottom-line" />
            <DecoRightLine className="deco-right-line" />
            <DecoCircle className="deco-circle" />
        </Content>
    </Wrapper>
)

export default ITradingKeyFeaturesGrid;
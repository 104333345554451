import { withTheme } from 'styled-components';
import { Heading, Text } from '../../../common.styles';
import './OpmMethod.css'
import topImage from '../../../assets/top_w.png'
import TrackVisibility from 'react-on-screen';
import * as React from "react";
import { useState, useEffect } from "react";
import TopButton from '../../../components/TopButton/TopButton';



const VisibilityWrapper = ({
    children,
}) => {
    const [isInitial, setIsInitial] = useState(false);

    return (
        <>
            <TrackVisibility partialVisibility>
                {
                    ({ isVisible }) => {
                        if (!isInitial && !!isVisible) {

                            setIsInitial(true);
                        }
                        return (
                            <div className={`fade-in-section ${isInitial ? 'is-visible' : ''}`}>
                                {children}
                            </div>
                        )
                    }
                }
            </TrackVisibility>
        </>
    )
}


const OpmMethod = (props) => {
    const { contentHashes } = props;
    return (
        <div>

            <div className="method-section">

                <div style={{ position: 'absolute', zIndex: '10', top: 60 }}>
                    <TopButton isWhite={true} marginLeft={30} />
                </div>

                <div>
                    <div className='item-wrapper'>

                        <div className='item'>
                            <div ref={(v) => { contentHashes[`#OpmApproach`] = v; }}></div>
                            <VisibilityWrapper>
                                <>
                                    <Heading className='abStyle'>Approach</Heading>
                                    <Text className='hrStyle'>Our approach is not simply to deliver the installation of a suitable piece of software but time spent understanding of your business and its processes. We listen to customers to ensure requirements are fully
                                        addressed and solutions are designed to address customer needs.
                                        <br />Our Project Management Office help to ensure that the project is well and properly managed to ensure the project goal is achieved
                                        efficiently in timely manner.
                                    </Text>
                                </>

                            </VisibilityWrapper>


                        </div>
                        <div className='item'>
                            <div ref={(v) => { contentHashes[`#OpmMethod`] = v; }}></div>
                            <VisibilityWrapper>
                                <>
                                    <Heading className='abStyle'>Method</Heading>
                                    <Text>Before we embark upon the set up and installation we discuss and
                                        understand your requirements and needs, both now and in the future.
                                        From your requirements we establish the initial configuration setup and design the system to fit the needs of the business ensuring that
                                        future requirements are not overlooked.
                                    </Text>
                                    <Text className='hrStyle'>Long after the solution has been implemented, we continue to provide
                                        ongoing support to ensure that it continues to function and
                                        grow with your organization.

                                    </Text>
                                </>
                            </VisibilityWrapper>

                        </div>

                    </div>
                </div>



            </div>
        </div >
    );
}

export default withTheme(OpmMethod)
import React from 'react';
import styled from 'styled-components';
import { device } from '../common.styles';

const IconWrapper = styled.span`
  width: 58.6px;
  height: 70px;

  @media only screen and ${device.mobileL} {
    width: 19.53px;
    height: 23.33px;
  }

  svg {
    fill: #4f7ac7;
  }
`;

const ProductiTrading2Icon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 58.606 69.962">
      <g transform="translate(-5434.977 -1110.704)">
        <rect width="16.951" height="53.222" rx="1.5" transform="translate(5476.631 1127.444)" />
        <rect width="16.951" height="69.962" rx="1.5" transform="translate(5455.804 1110.703)" />
        <rect width="16.951" height="36.481" rx="1.5" transform="translate(5434.977 1144.184)" />
      </g>
    </svg>


  </IconWrapper>
);

export default ProductiTrading2Icon;




import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  // cursor: pointer;
  margin: 0 4px;
`;

const ProductPrimeIcon = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 18.42 12.01">
      <path d="M44,603.73a.28.28,0,0,0-.28-.28H33.88v-6.74a.56.56,0,0,1,.56-.55H46.81a.55.55,0,0,1,.56.55v3a.27.27,0,0,0,.28.28.28.28,0,0,0,.28-.28h0v-3a1.12,1.12,0,0,0-1.12-1.11H34.45a1.11,1.11,0,0,0-1.12,1.11v7.4a1.12,1.12,0,0,0,1.12,1.12h4.67v1.15H38a.28.28,0,0,0,0,.56h5.3a.28.28,0,0,0,.29-.26.28.28,0,0,0-.26-.3H42.14v-1.15h1.6A.29.29,0,0,0,44,605h0Zm-3.27.88h-.28a.29.29,0,0,1-.26-.3.27.27,0,0,1,.26-.26h.28a.28.28,0,1,1,0,.56Z" transform="translate(-33.33 -595.6)" />
      <path d="M40.63,599.4a.61.61,0,1,0,0,1.22.61.61,0,0,0,0-1.22Z" transform="translate(-33.33 -595.6)" />
      <path d="M38.57,599.4a.6.6,0,0,0-.6.61.61.61,0,1,0,.6-.61Z" transform="translate(-33.33 -595.6)" />
      <path d="M42.68,599.4a.61.61,0,1,0,0,1.22.61.61,0,0,0,.61-.61h0A.61.61,0,0,0,42.68,599.4Z" transform="translate(-33.33 -595.6)" />
      <path d="M48.15,602.73A1.27,1.27,0,1,0,49.42,604,1.26,1.26,0,0,0,48.15,602.73Zm0,2a.79.79,0,1,1,.79-.79h0A.78.78,0,0,1,48.15,604.78Z" transform="translate(-33.33 -595.6)" />
      <path d="M51.14,603h-.39l0-.08.27-.27a.62.62,0,0,0,0-.87l-.6-.6a.62.62,0,0,0-.86,0l-.27.28-.08,0V601a.61.61,0,0,0-.61-.61h-.85a.61.61,0,0,0-.61.61h0v.4l-.07,0-.28-.28a.63.63,0,0,0-.87,0l-.6.6a.62.62,0,0,0,0,.86l.28.29,0,.07h-.4a.6.6,0,0,0-.6.61h0v.85a.6.6,0,0,0,.6.6h.4l0,.08-.29.28a.62.62,0,0,0,0,.86l.6.6a.61.61,0,0,0,.86,0h0l.28-.28.07,0V607a.61.61,0,0,0,.61.61h.85a.62.62,0,0,0,.61-.61v-.39l.08,0,.27.27a.61.61,0,0,0,.86,0h0l.6-.6a.6.6,0,0,0,0-.86l-.27-.27,0-.09h.39a.6.6,0,0,0,.61-.6v-.85A.61.61,0,0,0,51.14,603Zm.13,1.46a.13.13,0,0,1-.13.13h-.66l0,0h0v0l0,0h0v.05h0a2.33,2.33,0,0,1-.14.33v0h0s0,0,0,.08h0a.19.19,0,0,0,0,.07v0a.25.25,0,0,0,0,.07h0l.4.4a.15.15,0,0,1,0,.09.13.13,0,0,1,0,.09l-.6.6a.14.14,0,0,1-.19,0l-.4-.4h0l-.07,0h0l-.08,0h0l-.08,0h0a2.52,2.52,0,0,1-.33.14h-.07l0,0h0l0,0v.06s0,0,0,0V607a.12.12,0,0,1-.12.13h-.85a.13.13,0,0,1-.13-.13h0v-.66l0,0v0l0,0h0l0,0h0a2.26,2.26,0,0,1-.33-.13h0L47,606h0l-.08,0h0l-.07.05-.4.4a.13.13,0,0,1-.18,0l-.6-.6a.18.18,0,0,1,0-.09.18.18,0,0,1,0-.1l.4-.4a.18.18,0,0,0,.05-.07h0a.19.19,0,0,0,0-.08h0a.25.25,0,0,0,0-.08h0a2.26,2.26,0,0,1-.13-.33h0l0,0v0h0v0l0,0h0l0,0h-.67a.13.13,0,0,1-.13-.13h0v-.85a.13.13,0,0,1,.13-.13h.65l.06,0h0l0,0h0a.09.09,0,0,0,0-.06h0c0-.11.09-.22.14-.33h0v0s0,0,0-.07h0a.19.19,0,0,0,0-.08h0l-.05-.07-.4-.4a.13.13,0,0,1,0-.1.11.11,0,0,1,0-.09l.6-.6a.13.13,0,0,1,.18,0l.4.4h0l.06,0h.21a1.56,1.56,0,0,1,.33-.14h.07l0,0h0v0l0,0V601a.13.13,0,0,1,.13-.13h.85a.13.13,0,0,1,.13.13h0v.56a0,0,0,0,0,0,0v.11h0v0l0,0h.07a2,2,0,0,1,.34.14h0l.08,0,.08,0h0l.06,0h0l.4-.4a.14.14,0,0,1,.19,0l.6.6a.13.13,0,0,1,0,.18h0l-.4.4a.16.16,0,0,0,0,.07h0a.24.24,0,0,0,0,.08h0s0,0,0,.08h0v0a1.64,1.64,0,0,1,.14.33h0l0,.06h0l0,0h0l.05,0h.64a.13.13,0,0,1,.13.13h0Z" transform="translate(-33.33 -595.6)" />
    </svg>
  </IconWrapper>
);

export default ProductPrimeIcon;




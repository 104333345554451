import React from 'react'




import disable1 from '../../../assets/disable1.png'
import disable2 from '../../../assets/disable2.png'
import disable3 from '../../../assets/disable3.png'
import lineHon from '../../../assets/nFont_hon_line.png'




import styled from 'styled-components'
import { Heading, InnerWrapper, Text } from '../../../common.styles'
import DecoDesc1 from '../../../DecoDesc1'
import DecoHeading from '../../../DecoHeading'
import TopButton from '../../../components/TopButton/TopButton'
import './nFontDisable.css'

const StyledInnerWrapper = styled(InnerWrapper)`
    max-width: 1200px;
    margin: auto;
    padding: 16px;
`;

const StyledHeading = styled(Heading)`
    color: #343434;
`;

const StyledText = styled(Text)`
    color: #343434;
`;

const StyledContentTitle = styled(StyledText)`
    font-family: AB;
    font-weight: bold;
    color: #00b0bd;
    margin-top: 30px;
`;

const NFontDisable = () => {
    const handleClick = event => {
        window.scrollTo(0, 0)
    };

    return (
        <div className='iBroker'>
            <DecoHeading heading="nFront Account Disabler" />
            <DecoDesc1>
                nFront Account Disabler can automatically disable inactive and dormant accounts within your Windows Active Directory. Disabling inactive accounts is not only a security best practice but it is also part of the PCI compliance requirements and the IRS 1075 guideline.
            </DecoDesc1>
            <StyledInnerWrapper>

                <div className='nFontPFContent'>
                    <StyledContentTitle>NEVER WORRY ABOUT DORMANT ACCOUNTS AGAINS.</StyledContentTitle>

                    <StyledText>
                        Wouldn't it be nice to automatically disable any accounts that have not logged on in three weeks?<br />
                        nFront Account Disabler can automatically disable inactive and dormant accounts within your Windows Active Directory.<br />
                        Disabling inactive accounts is not only a security best practice but it is also part of the <a target='_blank' href='https://www.pcisecuritystandards.org/' style={{ color: `#00b0bd` }}>PCI compliance</a> requirements and the <a target='_blank' href='https://www.irs.gov/pub/irs-pdf/p1075.pdf' style={{ color: `#00b0bd` }}>IRS 1075 guideline</a>.
                    </StyledText>


                </div>


                <div className='nFontPFContent'>
                    <StyledContentTitle>A FULLY AUTOMATED SOLUTION:</StyledContentTitle>

                    <StyledText>
                        Some utilities simply offer reporting and leave the work of disabling acounts up to you. nFromt Account Disabler is different. If is fully automated and once configured you need only review the daily activity reports which can be emailed to you in an HTML or PDF format.
                    </StyledText>


                </div>


                <StyledHeading style={{ marginTop: `30px`, textAlign: 'center' }}>Features</StyledHeading>
                <StyledText style={{ marginTop: `20px` }}>
                    Determines last "true logon time" for all active directory accounts. In other words, it scans across all domsin controllers to get the correct last logon time for each user.<br />
                    Can disable accounts even though all domain  controllers are not available at the time of the query.<br />
                    Can skip system accounts like IUSR_&lt;machine-name&gt;<br />
                    Do not disable the build-in Administrator account.<br />
                    Do not disable specific groups like a group for service accounts.<br />
                    Generates local HTML reports.<br />
                    Can email a PDF or HTML report of the dormant accounts to an Administrator.<br />
                    Builds a CSV file of disabled accounts.<br />
                    Maintains a running log of all accounts that have been disabled by nFront Account Disabler. This log does not track accounts that have been disabled outside of nFront Account Disabler.<br />
                    Smart enough to skip accounts that you created yesterday whose last logon time is "never."
                </StyledText>

                <br />
            </StyledInnerWrapper>

            <TopButton />

            <StyledInnerWrapper>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledHeading style={{ textAlign: 'center' }}>Up And Running In 5 Minutes</StyledHeading>

                    <img src={disable1} style={{ maxWidth: `800px`, width: `100%` }} />

                    <StyledText>Example Report of Dormant Accounts:</StyledText>

                    <img src={disable2} style={{ maxWidth: `200px`, width: `100%`, marginBottom: `20px`, marginTop: `30px` }} />

                    <img src={disable3} style={{ maxWidth: `900px`, width: `100%`, marginBottom: `40px` }} />
                </div>

                <img src={lineHon} width="100%" />

                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <StyledContentTitle>
                        Limitations Of The Evaluation Version
                    </StyledContentTitle>


                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        Reports up to 3 inactive accounts<br />
                        Does not disable any inactive accounts
                    </StyledText>

                    <img src={lineHon} width="100%" />

                </div>



                <div style={{ display: `flex`, flexDirection: `column` }}>

                    <StyledContentTitle>
                        System Requirements
                    </StyledContentTitle>


                    <StyledText style={{ marginTop: `20px`, marginBottom: `20px` }}>
                        Windows 2003, 2008, 2012 or 2016 server<br />
                        2 MB free disk space<br />
                        Microsoft .NET Framework 2.0 or later
                    </StyledText>
                </div>

            </StyledInnerWrapper >



        </div >
    )
}

export default NFontDisable
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 0 4px;

  svg {
    fill: #f8ca42;
  }
`;

const QuoteRight = ({ className, ...otherProps }) => (
  <IconWrapper className={className} {...otherProps}>
    <svg viewBox="0 0 49.87 39.67">
      <path d="M1202.91,0V15.83L1196,39.67h-10.26l4.88-23.28h-8.44V0Zm-29.16,0V15.83l-6.88,23.84H1156.6l4.88-23.28H1153V0Z" transform="translate(-1153.04)" />
    </svg>
  </IconWrapper>
);

export default QuoteRight;
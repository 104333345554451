import React from 'react';
import { Element } from 'react-scroll';
import './SolutionContent.css';



// import OpmCloud from '../opmCloud/OpmCloud';
// import ITrading from '../iTrading/ITrading';
// import Prime from '../prime/Prime';
// import LDapProtext from '../LdapProtect/LDapProtect';
// import LDapProtect from '../LdapProtect/LDapProtect';
// import CMSmart from '../Cmsmart/CmSmart';
// import IStorage from '../iStorage/IStorage';
// import Digittrade from '../digittrade/Digittrade';
// import NFontPwd from '../nFontPwd/NFontPwd';
// import NFontWPC from '../nFontWPC/NFontWpc';
// import NFontDisable from '../nFomtDisable/NFontDisable';
// import NavbarSolution from '../navSolution/NavbarSolution';
import ChatBot from '../AI/ChatBot/ChatBot';
import ImageRec from '../AI/ImageRecognition/ImageRecognition';
import Ai from '../AI/NLP/NLP';
import Ocr from '../AI/OCR/OCR';
import DataProtection from '../Blockchain/DataProtection/DataProtection';
import Transaction from '../Blockchain/TransactionProcessing/TransactionProcessing';
import Booking from '../ByIndustry/Booking/Booking';
import CallLogSystem from '../ByIndustry/CallLog/CallLogSystem';
import CareManagement from '../ByIndustry/CaseManagement/CaseManagement';
import HealthCareSolution from '../ByIndustry/HealthCare/HealthCareSolution';
import LiveSreaming from '../ByIndustry/interactiveLiveStreaming/LiveStreaming';
import EndUserProtal from '../EndUser/EndUserPortalSolution/EndUserPortalSolution';
import MobileeSolution from '../EndUser/MobileEnterpriseSolution/MobileEnterpriseSolution';
import VirtualDesktop from '../EndUser/VDI/VDI';
import Hybrid from '../HybridCloudInfrastructure/K8S/K8S';
import PresistStorage from '../HybridCloudInfrastructure/PersistentStorage/PersistentStorage';
import EndPointSolution from '../Security/EndPointSecuritySolution/EndPointSecuritySolution';
import Identity from '../Security/IdentityAccessManagement/SecurityIdentity';
import MultiFactor from '../Security/MultiFactorAuthentication/MultiFactorAuthentication';
import SSO from '../Security/SSO/SSO';

import { useMediaQuery } from 'react-responsive';
import SolutionAiIcon from '../../../assets/SolutionAiIcon';
import SolutionBlockchainIcon from '../../../assets/SolutionBlockchainIcon';
import SolutionEndUserIcon from '../../../assets/SolutionEndUserIcon';
import SolutionHybridIcon from '../../../assets/SolutionHybridIcon';
import SolutionIndustryIcon from '../../../assets/SolutionIndustryIcon';
import SolutionSecurityIcon from '../../../assets/SolutionSecurityIcon';
import { device } from '../../../common.styles';
import Orchestrator from '../HybridCloudInfrastructure/Orchestrator/Orchestrator';
import PrivateRegistry from '../HybridCloudInfrastructure/PrivateRegistry/PrivateRegistry';
import SideMenuContent from '../../../SideMenuContent';
import AuthN from '../Blockchain/Authentication/Authentication';


const SECTIONS = {
  SECURITY: 'SECURITY',
  SECURITY_IAM: 'SECURITY_IAM',
  SECURITY_EPSS: 'SECURITY_EPSS',
  SECURITY_SSO: 'SECURITY_SSO',
  SECURITY_MFA: 'SECURITY_MFA',

  END_USER: 'END_USER',
  END_USER_PORTAL: 'END_USER_PORTAL',
  MOBILE_ENTERPRISE: 'MOBILE_ENTERPRISE',
  VIRTUAL_DESKTOP: 'VIRTUAL_DESKTOP',

  BY_INDUSTRY: 'BY_INDUSTRY',
  HEALTH_CARE: 'HEALTH_CARE',
  CASE_MANAGEMENT: 'CASE_MANAGEMENT',
  CALL_LOG_SYSTEM: 'CALL_LOG_SYSTEM',
  STREAMING_SYSTEM: 'STREAMING_SYSTEM',

  BLOCKCHAIN: 'BLOCKCHAIN',
  AUTHENTICATION: 'AUTHENTICATION',
  DATA_PROTECTION: 'DATA_PROTECTION',
  BOOKING_SYSTEM: 'BOOKING_SYSTEM',
  TRANSACTION: 'TRANSACTION',

  AI: 'AI',
  NLP: 'NLP',
  CHATBOT: 'CHATBOT',
  OPTICAL_CHRACTER: 'OPTICAL_CHRACTER',
  IMAGE_RECOGNITION: 'IMAGE_RECOGNITION',
  HYBRID_CLOUD_INFRA: 'HYBRID_CLOUD_INFRA',
  K8S: 'K8S',
  PERSIST_STORAGE: 'PERSIST_STORAGE',
  PRIVATE_REGISTRY: 'PRIVATE_REGISTRY',
  ORCHESTRATOR: 'ORCHESTRATOR',
}

const menuItems = [
  {
    label: 'Security', icon: <SolutionSecurityIcon />, element: SECTIONS.SECURITY,
    child: [
      { label: 'Identity & Access Management', element: SECTIONS.SECURITY_IAM },
      { label: 'End Point Security Solution', element: SECTIONS.SECURITY_EPSS },
      { label: 'Single Sign-on (SSO)', element: SECTIONS.SECURITY_SSO },
      { label: 'Multi-factor Authentication', element: SECTIONS.SECURITY_MFA },
    ]
  },
  {
    label: 'End User', icon: <SolutionEndUserIcon />, element: SECTIONS.END_USER,
    child: [
      { label: 'End User Portal Solution', element: SECTIONS.END_USER_PORTAL },
      { label: 'Mobile Enterprise Solution', element: SECTIONS.MOBILE_ENTERPRISE },
      { label: 'Virtual Desktop Infrasture (VDI)', element: SECTIONS.VIRTUAL_DESKTOP },
    ]
  },
  {
    label: 'By Industry', icon: <SolutionIndustryIcon />, element: SECTIONS.BY_INDUSTRY,
    child: [
      { label: 'Health Care Solution', element: SECTIONS.HEALTH_CARE },
      { label: 'Case Management System', element: SECTIONS.CASE_MANAGEMENT },
      { label: 'Call Log System', element: SECTIONS.CALL_LOG_SYSTEM },
      { label: 'Booking System', element: SECTIONS.BOOKING_SYSTEM },
      { label: 'Interactive Live Streaming System', element: SECTIONS.STREAMING_SYSTEM },
    ]
  },
  {
    label: 'Blockchain', icon: <SolutionBlockchainIcon />, element: SECTIONS.BLOCKCHAIN,
    child: [
      { label: 'Authentication', element: SECTIONS.AUTHENTICATION },
      { label: 'Data Protection', element: SECTIONS.DATA_PROTECTION },
      { label: 'Transaction Proccessing', element: SECTIONS.TRANSACTION },
    ]
  },
  {
    label: 'Artifical Intelligence (AI)', icon: <SolutionAiIcon />, element: SECTIONS.AI,
    child: [
      { label: 'Natural Language Processing (NLP)', element: SECTIONS.NLP },
      { label: 'ChatBot', element: SECTIONS.CHATBOT },
      { label: 'Optical Character Recognition (OCR)', element: SECTIONS.OPTICAL_CHRACTER },
      { label: 'Image Recognition', element: SECTIONS.IMAGE_RECOGNITION },
    ]
  },
  {
    label: 'Hybrid Cloud Infrastructure', icon: <SolutionHybridIcon />, element: SECTIONS.HYBRID_CLOUD_INFRA,
    child: [
      { label: 'Kubernetes (K8s)', element: SECTIONS.K8S },
      { label: 'Persistent Storage', element: SECTIONS.PERSIST_STORAGE },
      { label: 'Private Registry', element: SECTIONS.PRIVATE_REGISTRY },
      { label: 'Orchestrator', element: SECTIONS.ORCHESTRATOR },
    ]
  },
]

const SolutionContent = () => {


  const contentHashes = {
    'SECURITY': null,
    'SECURITY_IAM': null,
    'SECURITY_EPSS': null,
    'SECURITY_SSO': null,
    'SECURITY_MFA': null,
    'END_USER': null,
    'END_USER_PORTAL': null,
    'MOBILE_ENTERPRISE': null,
    'VIRTUAL_DESKTOP': null,
    'BY_INDUSTRY': null,
    'HEALTH_CARE': null,
    'CASE_MANAGEMENT': null,
    'CALL_LOG_SYSTEM': null,
    'STREAMING_SYSTEM': null,
    'BLOCKCHAIN': null,
    'AUTHENTICATION': null,
    'DATA_PROTECTION': null,
    'BOOKING_SYSTEM': null,
    'TRANSACTION': null,
    'AI': null,
    'NLP': null,
    'CHATBOT': null,
    'OPTICAL_CHRACTER': null,
    'IMAGE_RECOGNITION': null,
    'HYBRID_CLOUD_INFRA': null,
    'K8S': null,
    'PERSIST_STORAGE': null,
    'PRIVATE_REGISTRY': null,
    'ORCHESTRATOR': null,

  }

  const isMobile = useMediaQuery({ query: device.mobileL });

  const refreshScroll = () => {
    var hash = window.location.hash;
    if (hash && contentHashes[hash] instanceof HTMLElement) {
      if (isMobile) {
        setTimeout(() => {
          const headerOffset = 80;
          const elementPosition = contentHashes[hash].getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({ top: offsetPosition });
        }, 100);
      } else {
        contentHashes[hash].scrollIntoView();
      }
    }
  }

  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      refreshScroll();
    }
  };

  return (
    <SideMenuContent menuTitle="Solutions" menuItems={menuItems}>
      <div ref={(v) => { contentHashes[`#SECURITY`] = v; }}></div>
      <Element name={SECTIONS.SECURITY}>
        <div ref={(v) => { contentHashes[`#SECURITY_IAM`] = v; }}></div>
        <Element name={SECTIONS.SECURITY_IAM}>
          <Identity />
        </Element>
        <div ref={(v) => { contentHashes[`#SECURITY_EPSS`] = v; }}></div>
        <Element name={SECTIONS.SECURITY_EPSS}>
          <EndPointSolution />
        </Element>
        <div ref={(v) => { contentHashes[`#SECURITY_SSO`] = v; }}></div>
        <Element name={SECTIONS.SECURITY_SSO}>
          <SSO />
        </Element>
        <div ref={(v) => { contentHashes[`#SECURITY_MFA`] = v; }}></div>
        <Element name={SECTIONS.SECURITY_MFA}>
          <MultiFactor />
        </Element>
      </Element>


      <div ref={(v) => { contentHashes[`#END_USER`] = v; }}></div>
      <Element name={SECTIONS.END_USER}>
        <div ref={(v) => { contentHashes[`#END_USER_PORTAL`] = v; }}></div>
        <Element name={SECTIONS.END_USER_PORTAL}>
          <EndUserProtal />
        </Element>
        <div ref={(v) => { contentHashes[`#MOBILE_ENTERPRISE`] = v; }}></div>
        <Element name={SECTIONS.MOBILE_ENTERPRISE}>
          <MobileeSolution />
        </Element>
        <div ref={(v) => { contentHashes[`#VIRTUAL_DESKTOP`] = v; }}></div>
        <Element name={SECTIONS.VIRTUAL_DESKTOP}>
          <VirtualDesktop />
        </Element>
      </Element>

      <div ref={(v) => { contentHashes[`#BY_INDUSTRY`] = v; }}></div>
      <Element name={SECTIONS.BY_INDUSTRY}>
        <div ref={(v) => { contentHashes[`#HEALTH_CARE`] = v; }}></div>
        <Element name={SECTIONS.HEALTH_CARE}>
          <HealthCareSolution />
        </Element>
        <div ref={(v) => { contentHashes[`#CASE_MANAGEMENT`] = v; }}></div>
        <Element name={SECTIONS.CASE_MANAGEMENT}>
          <CareManagement />
        </Element>
        <div ref={(v) => { contentHashes[`#CALL_LOG_SYSTEM`] = v; }}></div>
        <Element name={SECTIONS.CALL_LOG_SYSTEM}>
          <CallLogSystem />
        </Element>
        <div ref={(v) => { contentHashes[`#BOOKING_SYSTEM`] = v; }}></div>
        <Element name={SECTIONS.BOOKING_SYSTEM}>
          <Booking />
        </Element>
        <div ref={(v) => { contentHashes[`#STREAMING_SYSTEM`] = v; }}></div>
        <Element name={SECTIONS.STREAMING_SYSTEM}>
          <LiveSreaming />
        </Element>
      </Element>

      <div ref={(v) => { contentHashes[`#BLOCKCHAIN`] = v; }}></div>
      <Element name={SECTIONS.BLOCKCHAIN}>
        <div ref={(v) => { contentHashes[`#AUTHENTICATION`] = v; }}></div>
        <Element name={SECTIONS.AUTHENTICATION}>
          <AuthN />
        </Element>
        <div ref={(v) => { contentHashes[`#DATA_PROTECTION`] = v; }}></div>
        <Element name={SECTIONS.DATA_PROTECTION}>
          <DataProtection />
        </Element>
        <div ref={(v) => { contentHashes[`#TRANSACTION`] = v; }}></div>
        <Element name={SECTIONS.TRANSACTION}>
          <Transaction />
        </Element>
      </Element>


      <div ref={(v) => { contentHashes[`#AI`] = v; }}></div>
      <Element name={SECTIONS.AI}>
        <div ref={(v) => { contentHashes[`#NLP`] = v; }}></div>
        <Element name={SECTIONS.NLP}>
          <Ai />
        </Element>
        <div ref={(v) => { contentHashes[`#CHATBOT`] = v; }}></div>
        <Element name={SECTIONS.CHATBOT}>
          <ChatBot />
        </Element>
        <div ref={(v) => { contentHashes[`#OPTICAL_CHRACTER`] = v; }}></div>
        <Element name={SECTIONS.OPTICAL_CHRACTER}>
          <Ocr />
        </Element>
        <div ref={(v) => { contentHashes[`#IMAGE_RECOGNITION`] = v; }}></div>
        <Element name={SECTIONS.IMAGE_RECOGNITION}>
          <ImageRec />
        </Element>
      </Element>

      <div ref={(v) => { contentHashes[`#HYBRID_CLOUD_INFRA`] = v; }}></div>
      <Element name={SECTIONS.HYBRID_CLOUD_INFRA}>
        <div ref={(v) => { contentHashes[`#K8S`] = v; }}></div>
        <Element name={SECTIONS.K8S}>
          <Hybrid />
        </Element>
        <div ref={(v) => { contentHashes[`#PERSIST_STORAGE`] = v; }}></div>
        <Element name={SECTIONS.PERSIST_STORAGE}>
          <PresistStorage />
        </Element>
        <div ref={(v) => { contentHashes[`#PRIVATE_REGISTRY`] = v; }}></div>
        <Element name={SECTIONS.PRIVATE_REGISTRY}>
          <PrivateRegistry />
        </Element>
        <div ref={(v) => { contentHashes[`#ORCHESTRATOR`] = v; }}></div>
        <Element name={SECTIONS.ORCHESTRATOR}>
          <Orchestrator />
        </Element>
      </Element>
    </SideMenuContent>
  )
}

export default SolutionContent

// export default function serviceContent() {

// }